<template>
  <NavBar
    :items="navBarItemsWithAuth"
    :config="{ bgNav: 'white', fgLink: '#2c3e50' }"
  ></NavBar>

  <div class="container" style="margin-top: 8rem">
    <TitleView
      title="Listado de usuarios"
      subtitle="Gestionar los usuarios de Landing Page"
    ></TitleView>

    <button
      class="btn btn-sm btn-primary d-block ms-auto mb-3"
      type="button"
      @click="toggleModal = !toggleModal"
    >
      Crear usuario
    </button>

    <span class="text-secondary d-block text-start mb-3">
      Total usuarios: {{ usuariosList.length }}
    </span>
    <TableComponent :headers="encabezadosUsuarios" :items="usuariosList">
      <template #actionslot="{ item }">
        <i
          class="btn btn-outline-danger fa-regular fa-trash-can"
          @click="deleteUser(item.user_ID, item.user_username)"
        ></i>
      </template>
    </TableComponent>
  </div>

  <Modal
    :visibility="toggleModal"
    :title="'Hola'"
    @toggle-modal="toggleModal = !toggleModal"
  >
    <FormNewUser @usuario-creado="usuarioCreado"></FormNewUser>
  </Modal>
</template>

<script lang="ts" setup>
import { Ref, onBeforeMount, ref } from "vue";
import Modal from "@/components/Modal.vue";
import TitleView from "@/components/TitleView.vue";
import FormNewUser from "@/components/FormNewUser.vue";
import TableComponent from "@/components/TableComponent.vue";
import { newApiRequest } from "@/services/apiRequest";
import NavBar from "@/components/NavBar.vue";
import { navBarItemsWithAuth } from "@/constants/generalConstants";
import { mostrarAlerta, mostrarToast } from "@/services/sweetAlertService";
import { UsuarioInterface } from "@/interfaces/ApiResponseInterfaces";
import { IHeader } from "@/interfaces/ComponentInterfaces";
import { DateTime } from "luxon";

const toggleModal: Ref<boolean> = ref(false);
const usuariosList: Ref<UsuarioInterface[]> = ref([]);

const usuarioCreado = () => {
  fetchUsers();
  mostrarToast({
    title: "Creado",
    text: "Se creó el usuario correctamente",
    icon: "success",
    timer: 6500,
    showCloseButton: true,
  });
  toggleModal.value = !toggleModal.value;
};

const encabezadosUsuarios: IHeader[] = [
  { key: "user_ID", label: "ID", sorteable: true },
  { key: "user_name", label: "Nombre", sorteable: true },
  { key: "user_username", label: "Nombre usuario", sorteable: true },
  { key: "user_role", label: "Rango", sorteable: true },
  { key: "fechaformateada", label: "Creación" },
  { key: "actionslot", label: "Acciones", slot: true },
];

const deleteUser = async (id: string, nombre: string) => {
  try {
    const accion = await mostrarAlerta({
      title: "Confirmación",
      text: `Se eliminará el usuario ${nombre}`,
      iconColor: "palevioletred",
      icon: "question",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Eliminar",
      focusCancel: true,
    });

    if (accion.isConfirmed) {
      const eliminado = await newApiRequest("/usuarios", {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ id: id }),
      });
      fetchUsers();
      mostrarToast({
        title: "Eliminado",
        text: "Se eliminó el usuario correctamente",
        icon: "success",
        timer: 4500,
        showCloseButton: true,
      });
    }
  } catch (error) {
    console.error(error);
  }
};

const fetchUsers = async () => {
  try {
    const { usuarios }: any = await newApiRequest("/usuarios");
    usuariosList.value = (usuarios as UsuarioInterface[]).map((usuario) => {
      return {
        ...usuario,
        user_role: usuario.user_role.toUpperCase(),
        fechaformateada: DateTime.fromISO(usuario.user_creation).toFormat(
          "dd-LLL-yyyy t"
        ),
      };
    });
  } catch (error) {
    mostrarAlerta({ title: "Error", text: String(error), icon: "error" });
  }
};

onBeforeMount(() => {
  fetchUsers();
});
</script>

<style scoped></style>
