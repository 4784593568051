import { BASE_API_URL, ENVIROMENT } from "@/constants/generalConstants";

export const obtenerExtension = (filename: string): string => {
  return filename.split(".").pop() || "";
};

export const obtenerRutaImagen = (ruta: string) => {
  if (ruta.startsWith("/") || ruta.startsWith("\\")) {
    ruta = ruta.slice(1);
  }
  const baseUrl = BASE_API_URL.replace("/landing", "");

  return `${baseUrl}/${ruta}`;
};
