import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import Modal from "./components/Modal.vue";
import "@fortawesome/fontawesome-free/css/all.css";

const pinia = createPinia();
const app = createApp(App);

app.component("Modal", Modal);

app.use(pinia);
app.use(router);

app.mount("#app");
