<template>
  <div class="whatsapp-bubble">
    <a href="https://wa.me/524438454471" target="_blank" title="Contactar al WhatsApp">
      <i class="fa-brands fa-whatsapp"></i>
    </a>
  </div>
</template>

<script lang="ts" setup></script>
<style scoped>
.body {
  position: relative !important;
}
.whatsapp-bubble {
  cursor: pointer;
  position: fixed;
  width: 45px;
  height: 45px;
  bottom: 20px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #25d366;
  border-radius: 50%;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  z-index: 100;
  transition: 0.3s all ease;
}
.whatsapp-bubble:hover {
  scale: 1.2;
}
.whatsapp-bubble a {
  color: whitesmoke;
}
.whatsapp-bubble a i {
  font-size: 1.7rem;
}
</style>
