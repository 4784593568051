<template>
  <div class="radio-branches">
    <div v-for="branch in branches" :key="branch.id">
      <input
        type="radio"
        name="sucursales"
        :id="branch.id"
        :value="branch.id"
        v-model="selectedBranch"
      />
      <label :for="branch.id">{{ branch.label }}</label>
    </div>
  </div>
  <template v-if="imagenes.length && selectedBranch == 'super'">
    <div
      id="sliderOffers"
      class="carousel slide"
      data-bs-ride="carousel"
      :class="[sliderFull ? 'carousel-expanded' : '']"
    >
      <button
        @click="fullScreenSlider(true)"
        title="Pantalla Completa"
        class="slider-btn slider-full"
        :style="{ display: sliderFull ? 'none' : 'flex' }"
      >
        <span class="material-symbols-outlined"> fullscreen </span>
      </button>
      <button
        class="slider-btn slider-close"
        @click="fullScreenSlider(false)"
        :style="{ display: sliderFull ? 'flex' : 'none' }"
        title="Cerrar pantalla completa"
      >
        <span class="material-symbols-outlined"> close </span>
      </button>
      <!-- CARUSEL INDICATORS -->
      <div class="carousel-indicators">
        <button
          v-for="(imagen, index) in imagenes"
          :key="imagen.imagen"
          type="button"
          data-bs-target="#sliderOffers"
          :data-bs-slide-to="index"
          :class="isFirstItem(index)['state']"
          :aria-current="isFirstItem(index)['aria-current']"
          :aria-label="'Slider' + (index + 1)"
        ></button>
      </div>
      <!-- CARUSEL INNER -->
      <div class="carousel-inner">
        <div
          v-for="(imagen, index) in imagenes"
          :key="imagen.imagen"
          class="carousel-item"
          :class="isFirstItem(index)['state']"
        >
          <img
            :src="
              'https://aztecaabarrotes.xyz/assets/uploads/verificador/' +
              imagen.imagen
            "
            class="d-block w-100 slider-img"
            alt="..."
          />
        </div>
      </div>
      <!-- BOTON PREV -->
      <button
        class="carousel-control-prev"
        type="button"
        data-bs-target="#sliderOffers"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <!-- BOTON NEXT -->
      <button
        class="carousel-control-next"
        type="button"
        data-bs-target="#sliderOffers"
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </template>
  <template v-else>
    <div class="no-offers" id="sliderOffers">
      <div>
        <img src="../assets/carrito.png" alt="carrito de compras" />
      </div>
      <div>
        <h3>Abarrotes <br /><span>Azteca</span></h3>
        <p class="text-secondary">
          Te invitamos a que visites nuestra sucursal y compruebes la calidad y
          los mejores precios que tenemos para ti
        </p>
      </div>
    </div>
  </template>
</template>

<script lang="ts" setup>
import { ref, watch, onMounted } from "vue";

const branches = [
  { id: "super", label: "Industrial", fetchID: 8 },
  { id: "obrera", label: "Obrera", fetchID: 1 },
  { id: "soli", label: "Solidaridad", fetchID: 2 },
  { id: "ultra", label: "Ultramarinos", fetchID: 3 },
  { id: "tijeras", label: "Tijeras", fetchID: 4 },
  { id: "tenencia", label: "Tenencia", fetchID: 5 },
];
let imagenes = ref([]);
let selectedBranch = ref("super");
let sliderFull = ref(false);

onMounted(() => {
  fetchBranchImages(8);
});

watch(selectedBranch, (newBranch: any) => {
  const sucursal = branches.find((branch) => branch.id == newBranch);
  if (sucursal) {
    fetchBranchImages(sucursal.fetchID);
  }
});

// FUNCION PARA AGREGAR ATRIBUTOS A PRIMER ELEMENTO DEL CARUSEL
const isFirstItem = (index: number) => {
  if (index == 0) {
    return {
      "aria-current": "true",
      state: "active",
    };
  }
  return {};
};

// TOGGLE DE PANTALLA COMPLETA
const fullScreenSlider = (state: boolean) => {
  sliderFull.value = state;
};

// FUNCION FETCH PARA IMAGENES
const fetchBranchImages = async (id: string | number) => {
  try {
    const response = await fetch(
      `https://aztecaabarrotes.xyz/Verificador/cargaOfertasAdmin/${id}`,
      {
        method: "POST",
      }
    );

    const data = await response.text();

    const arrayImages = JSON.parse(data);
    if (arrayImages.length) {
      imagenes.value = arrayImages;
    } else {
      imagenes.value = [];
    }
  } catch (error) {
    console.error(error);
  }
};
</script>

<style scoped>
.radio-branches {
  margin: 0 auto 20px auto;
  width: min(90%, 768px);
  padding: 1rem 3rem;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
  background-color: var(--primary-color);
  border-radius: 30px;
}
.radio-branches div :nth-child(1) {
  margin-right: 5px;
}
.radio-branches div label {
  color: whitesmoke;
  font-family: "Montserrat", sans-serif;
}
.carousel {
  display: flex;
  margin: 0 auto;
  width: min(95%, 1180px);
  /* overflow: hidden; */
  background-image: linear-gradient(
    to left top,
    #01161e,
    #061924,
    #0c1c2a,
    #131f30,
    #1b2135,
    #1d2237,
    #20223a,
    #23233c,
    #20233e,
    #1b243f,
    #162441,
    #102542
  );
  height: 70vh;
}
.carousel-expanded {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1010;
}
.carousel-item {
  height: 90%;
}
.carousel.carousel-expanded .carousel-item {
  height: 100%;
}
.carousel-item img {
  height: 100%;
  object-fit: contain;
}
.slider-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  margin: 0 auto;
  background-color: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  color: white;
  outline: none;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  z-index: 200;
  transition: 0.4s;
}
.slider-btn:hover {
  filter: brightness(110%);
  scale: 1.05;
}
.slider-full {
  background-color: var(--primary-color);
}
.slider-close {
  right: 40px;
  background-color: crimson;
}
.no-offers {
  display: flex;
  margin: 0 auto;
  width: min(95%, 1180px);
  /* overflow: hidden; */
  /* background-image: linear-gradient(
    to left top,
    #9b80ff,
    #908afe,
    #8892fb,
    #839af6,
    #82a0f0,
    #7b9beb,
    #7497e7,
    #6d92e2,
    #5c81dd,
    #4c71d7,
    #3f60d1,
    #334ec9
  ); */
  height: 70vh;
}
.no-offers h3 {
  font-family: "MISTRAL", "Poppins", sans-serif;
  text-transform: uppercase;
}
.no-offers div img {
  display: block;
  width: 600px;
  transition: 1.5s;
  align-self: center;
}
.no-offers div:nth-child(2) {
  align-self: center;
}
.no-offers div:nth-child(2) h3 {
  font-family: "Ubuntu", sans-serif;
  font-size: 1.5rem;
  text-transform: uppercase;
}
.no-offers div:nth-child(2) h3 span:first-of-type {
  font-family: "MISTRAL", sans-serif;
  font-size: 2.5rem;
  text-transform: uppercase;
}
.no-offers div:nth-child(2) p {
  font-family: "Poppins", "Montserrat", sans-serif;
  font-size: 1.1rem;
}
@media (max-width: 992px) {
  .no-offers div img {
    display: block;
    width: 400px;
  }
}
@media (max-width: 768px) {
  .no-offers {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .no-offers div img {
    width: 300px;
  }
}
@media (max-width: 568px) {
  .carousel {
    height: 45vh;
  }
  .carousel-expanded {
    height: 100vw !important;
    width: 100vh !important;
    left: 100% !important;
    transform: rotate(90deg);
    transform-origin: left top;
  }
  /* .carousel-item img {
    object-position: top;
    margin-top: 4rem;
  } */
}
</style>
