import { BASE_API_URL } from "@/constants/generalConstants";
import { useAuthStore } from "@/stores/auth";

export const newApiRequest = async (
  url: string,
  options?: RequestInit,
  externalAPI?: boolean,
  waitingBlob?: boolean
) => {
  const authStore = useAuthStore();

  if (authStore.token) {
    const headers = {
      ...options?.headers,
      Authorization: `Bearer ${authStore.token}`,
    };
    options = { ...options, headers };
  }

  const endpoint = externalAPI ? url : `${BASE_API_URL}${url}`;

  try {
    const rawResponse: Response = await fetch(endpoint, options);
    const response = waitingBlob
      ? await rawResponse.blob()
      : await rawResponse.json();
    if (!rawResponse.ok) {
      if (rawResponse.status == 401) {
        authStore.logout();
      }
      throw new Error(
        `${
          response.error ||
          response.err ||
          response.message ||
          response.mge ||
          ""
        }`
      );
    }
    return response;
  } catch (error) {
    throw error;
  }
};
