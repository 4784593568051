import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import SucursalMapsView from "@/views/SucursalMapsView.vue";
import OfertasIndustrialView from "@/views/OfertasIndustrialView.vue";
import SubirExcelOfertasView from "@/views/SubirExcelOfertasView.vue";
import LoginView from "@/views/LoginView.vue";
import GestionarProductos from "@/views/GestionarProductos.vue";
import OfertasActivasView from "@/views/OfertasActivasView.vue";
import UsariosView from "@/views/UsariosView.vue";
import PageNotFound from "@/views/PageNotFound.vue";
import { useAuthStore } from "@/stores/auth";
import { mostrarToast } from "@/services/sweetAlertService";
import FondosParaOfertasView from "@/views/FondosParaOfertasView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/azteca/inicio",
    name: "home",
    component: HomeView,
  },
  {
    path: "/azteca/login",
    name: "lalogin",
    component: LoginView,
  },
  {
    path: "/azteca/ofertas/industrial",
    name: "laofertassuper",
    component: OfertasIndustrialView,
  },
  {
    path: "/azteca/ofertas/excel",
    name: "laofertasexcel",
    component: SubirExcelOfertasView,
    meta: { requiresAuth: true },
  },
  {
    path: "/azteca/ofertas/productos",
    name: "laofertasproductos",
    component: GestionarProductos,
    meta: { requiresAuth: true },
  },
  {
    path: "/azteca/ofertas/activas",
    name: "laofertasactivas",
    component: OfertasActivasView,
    meta: { requiresAuth: true },
  },
  {
    path: "/azteca/sucursales",
    name: "lasucursales",
    component: SucursalMapsView,
  },
  {
    path: "/azteca/usuarios",
    name: "lausuarios",
    component: UsariosView,
    meta: { requiresAuth: true, roles: ["admin"] },
  },
  {
    path: "/azteca/fondos",
    name: "lafondosofertas",
    component: FondosParaOfertasView,
    meta: { requiresAuth: true },
  },
  {
    path: "/about",
    name: "about",
    meta: { requiresAuth: true },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "lapagenotfound",
    component: PageNotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();

  if (to.fullPath == "/") {
    router.replace({ name: "home" });
  } else if (to.meta.requiresAuth && !authStore.token) {
    // Si la ruta requiere autenticación y no está autenticado, redirigir al login
    mostrarToast({
      title: "Advertencia",
      text: "Necesitas iniciar sesión para acceder a la ruta",
      showCloseButton: true,
      timer: 4500,
      icon: "warning",
    });
    router.replace({ name: "lalogin" });
  } else if (to.meta.requiresAuth && authStore.token) {
    const routeRoles = to.meta.roles as string[] | undefined;

    if (routeRoles && !routeRoles.includes(authStore.roleUsuario as string)) {
      // Si el rol del usuario no está en la lista de roles permitidos
      alert("Tu tipo de usuario no tiene permisos para acceder a esta ruta.");
      return;
    }
  }

  // Permitir el acceso a la ruta
  next();
});

export default router;
