<template>
  <div class="file-upload">
    <span class="file-label">{{ label }}</span>
    <label :class="['custom-file-upload', { disabled: disabled }]">
      <span class="material-symbols-outlined"> {{ icon }} </span>
      <span>Seleccionar Archivo {{ shortLabel }}</span>
      <input
        type="file"
        :id="name"
        :name="name"
        :accept="accept"
        :required="required"
        :multiple="multiple"
        :disabled="disabled"
        @change="handleFileChange"
        @invalid="handleInvalid"
      />
    </label>

    <div v-if="file" class="file-details">
      <p class="text-primary">Archivo seleccionado: {{ file.name }}</p>
      <p class="text-secondary">
        Tamaño del archivo: {{ (file.size / 1024).toFixed(2) }} KB
      </p>
      <img
        v-if="isImage(file)"
        :src="previewUrl"
        alt="Vista previa de la imagen"
      />
      <button class="remove-file" @click="clearFile">×</button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onBeforeUnmount, defineProps } from "vue";
import { FileUploadInterface } from "@/interfaces/FormElInterfaces";
import { mostrarAlerta } from "@/services/sweetAlertService";

// Asegúrate de que FileUploadInterface está correctamente importado y utilizado
const props = defineProps<FileUploadInterface>();

const file = ref<File | null>(null);
const previewUrl = ref<string | undefined>(undefined);

const emit = defineEmits<{ (e: "cambioArchivo"): void }>();

const handleFileChange = (event: Event) => {
  const target = event.target as HTMLInputElement;
  if (target.files && target.files.length > 0) {
    file.value = target.files[0];
    previewUrl.value = URL.createObjectURL(file.value);
  }
  emit("cambioArchivo");
};

const clearFile = () => {
  file.value = null;
  previewUrl.value = undefined;
  const inputElement = document.getElementById(props.name) as HTMLInputElement;
  emit("cambioArchivo");
  if (inputElement) {
    inputElement.value = "";
  }
  if (file.value) {
    URL.revokeObjectURL(file.value);
  }
};

const isImage = (file: File | null): boolean => {
  return file ? file.type.startsWith("image/") : false;
};

const handleInvalid = (e: Event) => {
  const input = e.target as HTMLInputElement;
  if (input.validity.valueMissing) {
    mostrarAlerta({
      title: "Sin archivos",
      text: "No haz seleccionado ningún archivo",
      icon: "warning",
      iconHtml: "<i class='fa-solid fa-file-circle-xmark'></i>",
      customClass: { icon: "custom-fa-icon" },
    });
  }
};

onBeforeUnmount(() => {
  if (previewUrl.value) {
    URL.revokeObjectURL(previewUrl.value);
  }
});
</script>

<style scoped>
.file-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  align-self: flex-start;
  width: min(90%, 350px);
  margin: 0 auto;
}

.file-label {
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: #333;
}

#form
  > fieldset:nth-child(1)
  > div.row.align-items-center
  > div.file-upload.col-sm-6.col-md-4 {
  align-self: center;
}

.custom-file-upload {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
  background-color: #3f51b5;
  color: whitesmoke;
  border-radius: 4px;
  transition: background-color 0.4s;
  font-size: 16px;
}

.custom-file-upload.disabled {
  background-color: #b0bec5;
  color: #9e9e9e;
  cursor: not-allowed;
}

.custom-file-upload:hover {
  background-color: #5160b4;
}

input[type="file"] {
  width: 0;
}

.material-icons {
  margin-right: 10px;
}

.file-details {
  margin-top: 20px;
  text-align: center;
  position: relative;
}

.file-details p {
  margin: 5px 0;
  color: #333;
  font-size: 14px;
}

img {
  margin-top: 10px;
  width: min(300px, 95%);
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

img:hover {
  transform: scale(1.05);
}

.remove-file {
  position: absolute;
  top: 0;
  right: -30px;
  background: red;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 18px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
