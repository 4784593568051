<script setup>
import { onMounted, ref } from "vue";

let map;
const marcadores = [
  {
    id: "obrera",
    title: "Abarrotes Azteca Obrera",
    pos: { lat: 19.712373013095757, lng: -101.18075168838472 },
    address: "Miguel Arriaga #396 Colonia Obrera",
    horary: "07:00 AM - 09:00 PM",
  },
  {
    id: "super",
    title: "Abarrotes Azteca Ciudad Industrial",
    pos: { lat: 19.731766628712347, lng: -101.13902737501556 },
    address: "Oriente 4 #999 Colonia Ciudad Industrial",
    horary: "07:00 AM - 09:00 PM",
  },
  {
    id: "solidaridad",
    title: "Abarrotes Azteca Solidaridad",
    pos: { lat: 19.731587629750525, lng: -101.20448609925748 },
    address: "Carlos Torres Manzo #35 Colonia Solidaridad",
    horary: "07:00 AM - 09:00 PM",
  },
  {
    id: "ultramarinos",
    title: "Abarrotes Azteca Ultramarinos",
    pos: { lat: 19.702562842349018, lng: -101.22882030111072 },
    address: "Vicente Guerrero #301 Colonia Patria",
    horary: "07:00 AM - 09:00 PM",
  },
  {
    id: "tijeras",
    title: "Abarrotes Azteca Tijeras",
    pos: { lat: 19.706178375981114, lng: -101.15345547391841 },
    address: "Juan de Baeza #600 Colonia Dieciocho de Mayo",
    horary: "07:00 AM - 09:00 PM",
  },
  {
    id: "tenencia",
    title: "Abarrotes Azteca Tenencia Morelos",
    pos: { lat: 19.64127947271455, lng: -101.23324361762388 },
    address: "Faisan #117 Tenencia Morelos",
    horary: "07:00 AM - 09:00 PM",
  },
  {
    id: "trincheras",
    title: "Abarrotes Azteca Trincheras",
    pos: { lat: 19.664093934594504, lng: -101.19835690122476 },
    address: "Encino #227 Colonia Colinas del Sur",
    horary: "07:00 AM - 09:00 PM",
  },
];
let mensajeHorario = ref("");
let claseHorario = ref("closed");
let userMarker;
let branchInfo = ref({});

const horaActual = new Date().getHours();
if (horaActual > 20 && horaActual < 21) {
  claseHorario.value = "toclose";
  mensajeHorario.value = "Proximo a cerrar";
} else if (horaActual >= 7 && horaActual < 21) {
  claseHorario.value = "open";
  mensajeHorario.value = "Abierto";
} else {
  claseHorario.value = "closed";
  mensajeHorario.value = "Cerrado";
}

onMounted(async () => {
  const { LatLng, LatLngBounds, ControlPosition, Size } =
    await google.maps.importLibrary("core");
  const { Map, InfoWindow } = await google.maps.importLibrary("maps");
  const { AdvancedMarkerElement, PinElement, Animation, Marker } =
    await google.maps.importLibrary("marker");

  // COORDS PARA LIMITAR MAPA
  let limitesZona = new LatLngBounds(
    new LatLng(19.54173926425861, -101.42468948298104), // Esquina suroeste de la zona permitida
    new LatLng(19.826854180477092, -100.92515490483603) // Esquina noreste de la zona permitida
  );

  // INICIAR MAPA
  map = new Map(document.getElementById("map"), {
    zoom: 13,
    center: { lat: 19.712344633755446, lng: -101.18077397672727 },
    mapId: "8810d933fcfe233d",
    restriction: {
      latLngBounds: limitesZona,
      strictBounds: false,
    },
  });

  // AGREGAR MARCADORES
  marcadores.forEach((branchMarker) => {
    const actualMarker = new Marker({
      map,
      title: branchMarker.title,
      position: branchMarker.pos,
      animation: Animation.DROP,
      icon: {
        url: "https://i.imgur.com/RGJv35w.png",
        scaledSize: new Size(80, 80),
      },
    });

    actualMarker.addListener("click", () => {
      map.setZoom(17);
      map.panTo(actualMarker.getPosition());
    });
  });

  userLocation(Marker);

  // AGREGAR BOTON DE UBICACION
  const locationButton = document.createElement("BUTTON");
  locationButton.classList.add("map-btn", "location-button");
  locationButton.setAttribute("title", "Ir a mi ubicación");
  locationButton.innerHTML =
    '<span class="material-symbols-outlined"> my_location </span>';
  map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(locationButton);
  locationButton.addEventListener("click", () => userLocation(Marker));

  // AGREGAR BOTON PARA LIMPIAR RUTAS
  const cleanButton = document.createElement("BUTTON");
  cleanButton.classList.add("map-btn", "clean-button");
  cleanButton.setAttribute("title", "Limpiar las rutas");
  cleanButton.innerHTML =
    '<span class="material-symbols-outlined"> mop </span>';
  map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(cleanButton);
  cleanButton.addEventListener("click", () => limpiarRuta());
});

function userLocation(Marker) {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const {
          coords: { latitude, longitude },
        } = position;
        if (userMarker == undefined) {
          userMarker = new Marker({
            map,
            title: "Tu ubicación",
            position: { lat: latitude, lng: longitude },
          });
        } else {
          userMarker.setPosition({ lat: latitude, lng: longitude });
          map.setZoom(17);
          map.panTo({ lat: latitude, lng: longitude });
        }
      },
      (error) => {
        console.error(error);
        alert(
          "El navegador soporta la geolocalización pero ocurrió un error\n" +
            error
        );
      },
      { enableHighAccuracy: true }
    );
  } else {
    alert("El navegador no soporta la geolocalización.");
  }
}

function selectedBranch(branch) {
  const selectedMarker = marcadores.find((marker) => marker.id == branch.id);
  map.setZoom(16);
  map.panTo(selectedMarker.pos);
}

const routeMarkers = [];
let directionsRenderer;

function routeToBranch(branch, e) {
  branchInfo.value = {};
  const directionsService = new google.maps.DirectionsService();

  // LIMPIAR RUTA ANTERIOR
  limpiarRuta();

  // RENDERIZAR NUEVA RUTA
  if (!directionsRenderer) {
    directionsRenderer = new google.maps.DirectionsRenderer();
    directionsRenderer.setMap(map);
  }
  directionsRenderer.setMap(map);
  try {
    if (userMarker == undefined) {
      throw new Error();
    }
    const request = {
      origin: userMarker.getPosition(),
      destination: branch.pos,
      travelMode: google.maps.TravelMode.DRIVING,
      language: "es",
    };
    directionsService.route(request, (result, status) => {
      if (status === google.maps.DirectionsStatus.OK) {
        directionsRenderer.setDirections(result);
        const route = result.routes[0];
        const leg = route.legs[0];
        let steps = leg.steps;

        // Obtener tiempo y distancia estimada
        const distance = leg.distance.value;
        const duration = leg.duration.value;

        // Agregar marcadores con etiquetas de índice en cada punto de giro
        steps.forEach((step, index) => {
          const marker = new google.maps.Marker({
            position: step.start_location,
            map: map,
            label: {
              text: (index + 1).toString(),
              color: "whitesmoke",
              fontSize: "16px",
              fontWeight: "bold",
            }, // Etiqueta de índice,
            cursor: "grab",
            title: step.instructions, // Instrucción en el título del marcador
          });

          const regex = /<\/?(b|wbr|div[^>]*)>|<\/?wbr\/?>/g;
          step.instructions = step.instructions.replace(regex, "");

          routeMarkers.push(marker);
        });

        branchInfo.value[branch.id] = {
          distance,
          duration,
          steps: steps,
        };
      } else {
        window.alert("No se encontró una ruta adecuada.");
      }
    });
  } catch (error) {
    console.error("No se puede obtener la ubicacion del usuario");
    return;
  }
}

// LIMPIA LA RUTA Y LOS MARCADORES ASOCIADOS
function limpiarRuta() {
  branchInfo.value = {};
  if (directionsRenderer) {
    directionsRenderer.setMap(null);
  }
  routeMarkers.forEach((marker) => {
    marker.setMap(null);
  });
  routeMarkers.length = 0;
}
</script>

<template>
  <section class="map-container">
    <div class="map-details">
      <h2 class="map-title">Sucursales de Abarrotes Azteca</h2>
      <ul class="branch">
        <li
          v-for="branch in marcadores"
          :key="branch.id"
          class="branch-item"
          @click="selectedBranch(branch)"
        >
          <span class="branch-title">{{ branch.title }}</span>
          <span class="branch-address">
            <span class="material-symbols-outlined"> map </span>
            {{ branch.address }}
          </span>
          <span class="branch-horary">
            <span class="material-symbols-outlined"> schedule </span>
            {{ branch.horary }}
            <span
              class="branch-state"
              :class="{
                open: claseHorario == 'open',
                closed: claseHorario == 'closed',
                toclose: claseHorario == 'toclose',
              }"
              >{{ mensajeHorario }}</span
            >
          </span>
          <br />
          <span @click.stop="routeToBranch(branch, $event)">
            <i class="fa-solid fa-route"></i> Ir
          </span>
          <div v-if="branchInfo[branch.id]" class="time-details">
            <p>
              Distancia Total:
              <b>
                {{ Math.floor(branchInfo[branch.id].distance / 1000) }}km
                {{ branchInfo[branch.id].distance % 1000 }}m
              </b>
            </p>
            <p>
              Tiempo estimado:
              <b>
                {{ Math.floor(branchInfo[branch.id].duration / 60) }}m
                {{ Math.floor(branchInfo[branch.id].duration % 60) }}s</b
              >
            </p>
            <span
              v-for="(step, index) in branchInfo[branch.id].steps"
              :key="step.instructions"
              class="step-ins"
            >
              <b>[{{ index + 1 }}]</b> {{ step.instructions }}
              <b>
                <span class="material-symbols-outlined step-icon">
                  straighten
                </span>
                {{
                  step.distance.value > 1000
                    ? `${(step.distance.value / 1000).toFixed(2)}km`
                    : `${step.distance.value}m`
                }}
              </b>
              <b>
                <span class="material-symbols-outlined step-icon"> timer </span>
                {{
                  step.duration.value > 60
                    ? `${Math.floor(step.duration.value / 60)}m ${
                        step.duration.value % 60
                      }s`
                    : `${step.duration.value}s`
                }}
              </b>
              <hr />
            </span>
          </div>
        </li>
      </ul>
    </div>
    <div id="map"></div>
  </section>
</template>

<style>
.map-container {
  position: relative;
  top: 80px;
  left: 0;
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 4fr;
}
#map {
  position: relative;
  min-height: 50vh;
}
.map-title {
  padding: 1rem;
  font-size: 1.2rem;
  font-family: "Poppins", sans-serif;
}
.map-details {
  height: calc(100vh - 100px);
  overflow-y: auto;
  overflow-x: hidden;
}
.branch {
  padding: 0;
  display: flex;
  flex-direction: column;
  list-style: none;
}
.branch-item {
  display: flex;
  flex-direction: column;
  padding: 2rem 1.2rem;
  background-color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.1s all ease;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.branch-item > span:last-of-type {
  align-self: flex-end;
  transition: 0.3s all ease;
}
.branch-item > span:last-of-type:hover {
  scale: 1.2;
  color: var(--primary-color);
}
.branch-title {
  margin-bottom: 1rem;
  color: var(--primary-color);
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: bold;
}
.branch-address {
  margin-bottom: 0.5rem;
}
.branch-address,
.branch-horary {
  font-family: "Poppins", sans-serif;
}
.branch-address span,
.branch-horary span {
  vertical-align: bottom;
  color: var(--primary-color);
}
.branch-state {
  margin-left: 10px;
  padding: 0.2rem 0.7rem;
  border-radius: 20px;
}
.open {
  background-color: #d4e9e2;
}
.closed {
  color: #333;
  background-color: lightcoral;
}
.toclose {
  color: #333;
  background-color: lightyellow;
}

.map-btn {
  color: white;
  background-color: var(--primary-color);
  height: 40px;
  width: 40px;
  border-radius: 50%;
  cursor: pointer;
  right: 9px !important;
  transition: 0.3s;
  border: none;
}
.map-btn:hover {
  scale: 1.1;
}
.map-btn:first-of-type {
  margin-bottom: 10px;
}
.location-button {
  background-color: var(--primary-color);
}
.clean-button {
  background-color: #ff5a00;
}
.time-details {
  padding: 1rem;
  font-family: "Poppins", "Ubuntu", sans-serif;
  background-color: aliceblue;
}
.time-details .step-ins {
  font-size: 0.9rem;
}
.step-icon {
  font-size: 16px;
  margin-left: 10px;
}
@media only screen and (max-width: 768px) {
  .map-container {
    display: flex;
    flex-direction: column-reverse;
  }
  .map-details {
    height: calc(100vh - 559.5px);
    overflow: auto;
  }
}
</style>
