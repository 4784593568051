<template>
  <div class="login-container">
    <h1>
      Administrador para ofertas Landing Page <br /><br />
      Azteca Abarrotes
    </h1>
    <div class="login-box">
      <h2 class="login-title">Iniciar sesion</h2>
      <form @submit.prevent="handleSubmit">
        <div class="input-group">
          <label for="username">Usuario</label>
          <input
            v-model.trim="username"
            type="text"
            id="username"
            name="username"
            required
          />
        </div>
        <div class="input-group">
          <label for="password">Contraseña</label>
          <div class="form-pass w-100">
            <input
              v-model.trim="password"
              :type="passType"
              id="password"
              name="password"
              required
            />
          </div>
          <i
            :class="`fa-regular ${passIcon} form-reveal-pass`"
            @click="togglePassView"
          ></i>
        </div>
        <button type="submit" class="login-btn">Ingresar</button>
      </form>
    </div>
    <router-link to="/azteca/inicio" class="text-warning">Ir a landing</router-link>
  </div>
</template>

<script lang="ts" setup>
import { useAuthStore } from "@/stores/auth";
import { ref, Ref } from "vue";

const username = ref<string>("");
const password = ref<string>("");
const passIcon: Ref<string> = ref("fa-eye");
const passType: Ref<string> = ref("password");

const togglePassView = (): void => {
  passIcon.value == "fa-eye"
    ? (passIcon.value = "fa-eye-slash")
    : (passIcon.value = "fa-eye");

  passType.value == "password"
    ? (passType.value = "text")
    : (passType.value = "password");
};

const auth = useAuthStore();

const handleSubmit = () => {
  auth.login(username.value, password.value);
};
</script>

<style scoped>
h1 {
  position: relative;
  color: #ffffff;
  font-size: 1.5rem;
  z-index: 15;
}
.login-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  height: 100vh;
  background: #0f0c29; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #24243e,
    #302b63,
    #0f0c29
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #24243e,
    #302b63,
    #0f0c29
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  z-index: 10;
}

/* Caja de login con efecto de blur y fondo transparente */
.login-box {
  position: relative;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  padding: 30px;
  backdrop-filter: blur(10px); /* Efecto de desenfoque */
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.37);
  border: 1px solid rgba(255, 255, 255, 0.18);
  width: 100%;
  max-width: 400px;
  text-align: center;
  z-index: 15;
}

/* Título del login */
.login-title {
  margin-bottom: 20px;
  font-size: 2rem;
  color: #ffffff;
}

/* Grupo de input estilo Material Design */
.input-group {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.input-group label {
  color: #ffffff;
  font-size: 1rem;
  margin-bottom: 5px;
}

.input-group input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  font-size: 1rem;
  outline: none;
  transition: all 0.3s ease;
}

/* Efecto de Material Design en el hover */
.input-group input:focus {
  border-color: #ffffff;
  background: rgba(255, 255, 255, 0.2);
}

/* Botón de login */
.login-btn {
  width: 100%;
  padding: 10px;
  background-color: #6200ea;
  color: white;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-btn:hover {
  background-color: #3700b3;
}
.form-pass {
  position: relative;
}
.form-reveal-pass {
  cursor: pointer;
  position: absolute;
  top: calc(50% + 13px);
  right: 12px;
  transform: translateY(-50%);
  color: #ccc;
}
.form-reveal-pass:hover {
  color: whitesmoke;
}
</style>
