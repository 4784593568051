<template>
  <form @submit.prevent="subirImagenProducto">
    <div class="product-desc ,b-1">{{ productId }}</div>
    <div class="product-desc mb-3">{{ productName }}</div>
    <FileInput
      label="Subir Imagen"
      short-label="Imagen Producto"
      name="imagen-producto"
      accept="image/*"
      icon="photo_camera"
      :required="true"
    ></FileInput>
    <Button
      button-color="#006064"
      text-color="white"
      button-text="Guardar Imagen"
      button-type="submit"
      icon="save"
    ></Button>
  </form>
</template>

<script lang="ts" setup>
import FileInput from "@/components/FileInput.vue";
import Button from "./Button.vue";
import { newApiRequest } from "@/services/apiRequest";
import { mostrarAlerta } from "@/services/sweetAlertService";

interface ProductImageProps {
  productId: string | number | null;
  productName: string | number | null;
}

const props = defineProps<ProductImageProps>();
const emit = defineEmits<{
  (e: "imagenCargada", value: Record<string, any>): void;
}>();

const subirImagenProducto = async (e: Event) => {
  const form = e.target as HTMLFormElement;
  const formData = new FormData(form);
  formData.append("codigo", props.productId as string);
  try {
    const imagenSubida = await newApiRequest("/guardar-imagen", {
      method: "POST",
      body: formData,
    });
    emit("imagenCargada", {
      nombre: props.productName as string,
      codigo: props.productId,
    });
  } catch (error) {
    mostrarAlerta({
      title: "Error",
      text: `${String(error).replaceAll("Error:", "")}`,
      icon: "error",
      iconHtml: '<i class="fa-solid fa-file-circle-exclamation"></i>',
    });
    console.error(error);
  }
};
</script>

<style scoped>
form {
  margin: 0 auto;
  width: min(480px, 90%);
  max-width: 480px;
}
.product-desc:first-of-type {
  color: darkslategray;
}
.product-desc {
  color: #777;
  font-weight: bold;
}
</style>
