<template>
  <NavBar
    :items="navBarItemsWithAuth"
    :config="{ bgNav: 'white', fgLink: '#2c3e50' }"
  ></NavBar>

  <div style="margin-top: 10rem"></div>

  <form @submit.prevent="handleSubmit">
    <FileInput
      label="Subir Archivo Excel"
      short-label="Excel de Ofertas"
      name="excel"
      accept=".xlsx, .xls"
      icon="attach_file"
      :disabled="disabledExcelInput"
      :required="true"
      @cambioArchivo="cleanDataFile"
    ></FileInput>
    <button type="submit" :class="[{ disabled: disabledExcelInput }]">
      Subir Archivo
    </button>
  </form>

  <div class="container" v-show="productosOrdenados.length">
    <ImagesBgPreview :itemImages="images"></ImagesBgPreview>
    <div class="excel-title encabezado">
      {{ encabezado }}
    </div>
    <div class="excel-title validez">
      {{ validez }}
    </div>
    <div class="total-products">
      Número de productos: {{ productosOrdenados.length }}
    </div>
    <div class="sort-products">
      <div>
        <label
          ><input type="radio" value="az" v-model="metodoOrdenamiento" />A →
          Z</label
        >
      </div>
      <div>
        <label>
          <input type="radio" value="za" v-model="metodoOrdenamiento" />Z →
          A</label
        >
      </div>
      <div>
        <label
          ><input
            type="radio"
            value="pricelower"
            v-model="metodoOrdenamiento"
          />Precio menor a mayor</label
        >
      </div>
      <div>
        <label
          ><input
            type="radio"
            value="pricehigher"
            v-model="metodoOrdenamiento"
          />Precio mayor a menor</label
        >
      </div>
    </div>

    <div class="product-container">
      <template v-for="producto in productosOrdenados" :key="producto[0]">
        <ExcelProduct
          :id="producto[0]"
          :name="producto[1]"
          :price="producto[2]"
          :quantity="producto[3]"
          :startDate="validezSQL.fechaInicial"
          :endDate="validezSQL.fechaFinal"
          :availableImages="images"
          :imagenGuardadaCorrectamente="actualImagenGuardada"
          @toggle-modal="toggleImageModal"
        ></ExcelProduct>
      </template>
    </div>
  </div>

  <Modal
    title="Agregar nueva imagen para producto"
    :visibility="showImageModal"
    @toggleModal="toggleImageModal"
  >
    <AddImageToProduct
      :productId="selectedProductId"
      :productName="selectedProductName"
      @imagenCargada="imagenCargada"
    ></AddImageToProduct>
  </Modal>
</template>

<script lang="ts" setup>
import { mostrarAlerta } from "@/services/sweetAlertService";
import NavBar from "@/components/NavBar.vue";
import FileInput from "@/components/FileInput.vue";
import ImagesBgPreview from "@/components/ImagesBgPreview.vue";
import ExcelProduct from "@/components/ExcelProduct.vue";
import AddImageToProduct from "@/components/AddImageToProduct.vue";
import { SubsequentSubarrays } from "@/interfaces/ExcelResponseInterfaces";
import { Ref, computed, onBeforeMount, reactive, ref } from "vue";
import { extraerFechasDeExcelOfertas } from "@/utils/dateUtilities";
import { newApiRequest } from "@/services/apiRequest";
import { navBarItemsWithAuth } from "@/constants/generalConstants";
import { ImageBg } from "@/interfaces/GeneralInterfaces";

const products: Ref<Array<SubsequentSubarrays>> = ref([]);
const metodoOrdenamiento: Ref<string> = ref("az");
const actualImagenGuardada = ref(null);

const productosOrdenados = computed(() => {
  if (metodoOrdenamiento.value == "az") {
    return products.value.sort((a: any, b: any) => a[1].localeCompare(b[1]));
  } else if (metodoOrdenamiento.value == "za") {
    return products.value.sort((a: any, b: any) => b[1].localeCompare(a[1]));
  } else if (metodoOrdenamiento.value == "pricelower") {
    return products.value.sort((a: any, b: any) => a[2] - b[2]);
  } else if (metodoOrdenamiento.value == "pricehigher") {
    return products.value.sort((a: any, b: any) => b[2] - a[2]);
  } else {
    return products.value;
  }
});

interface ValidezSQL {
  fechaInicial: string;
  fechaFinal: string;
}

const encabezado: Ref<string> = ref("");
const validez: Ref<string> = ref("");
const showImageModal: Ref<boolean> = ref(false);
const validezSQL = reactive<ValidezSQL>({ fechaInicial: "", fechaFinal: "" });
const disabledExcelInput: Ref<boolean> = ref(false);
const selectedProductId = ref<number | string | null>(null);
const selectedProductName = ref<string | number | null>(null);
const images = ref<ImageBg[]>([]);

const emit = defineEmits<{ (e: "subido", value: FormData): void }>();

const toggleImageModal = (id: number | string, descripcion: string) => {
  selectedProductId.value = id;
  selectedProductName.value = descripcion;
  showImageModal.value = !showImageModal.value;
};

const imagenCargada = (value: Record<string, any>) => {
  showImageModal.value = false;
  actualImagenGuardada.value = value.codigo;
  mostrarAlerta({
    title: "Imagen guardada",
    iconHtml: "<i class='fa-regular fa-floppy-disk'></i>",
    text: `Se guardo la imagen correctamente para ${value.nombre}`,
    customClass: { icon: "custom-fa-icon" },
  });
};

const cleanDataFile = () => {
  products.value = [];
};

const handleSubmit = async (e: Event) => {
  const formData = new FormData(e.target as HTMLFormElement);
  disabledExcelInput.value = true;
  try {
    const excelSubido = await newApiRequest("/excel", {
      method: "POST",
      body: formData,
    });

    if (excelSubido.elementosExcel && excelSubido.elementosExcel.length) {
      encabezado.value = excelSubido.elementosExcel[0][0];
      validez.value = excelSubido.elementosExcel[1][0];
      validezSQL.fechaInicial =
        extraerFechasDeExcelOfertas(excelSubido.elementosExcel[1][0])
          .fechaInicial || "";
      validezSQL.fechaFinal =
        extraerFechasDeExcelOfertas(excelSubido.elementosExcel[1][0])
          .fechaFinal || "";

      products.value = excelSubido.elementosExcel.slice(3);
    }
  } catch (error) {
    const err = String(error).replaceAll("Error:", "");
    mostrarAlerta({
      icon: "error",
      title: "Error",
      text: `Ocurrió algo inesperado al intentar subir el excel: ${err}`,
      showCancelButton: false,
      showConfirmButton: false,
    });
  } finally {
    disabledExcelInput.value = false;
  }
};

const fetchBgImages = async () => {
  try {
    const data = await newApiRequest("/imagenes-oferta/fondo");
    // Asegúrate de que la propiedad `imagenes` existe y es un arreglo
    if (data && Array.isArray(data.imagenes)) {
      images.value = data.imagenes;
    } else {
      console.error("La respuesta del servidor no tiene el formato esperado.");
    }
  } catch (error) {
    mostrarAlerta({
      icon: "error",
      title: "Error",
      text: error as string,
      showCancelButton: false,
      showConfirmButton: false,
    });
  }
};

onBeforeMount(() => {
  fetchBgImages();
});
</script>

<style scoped>
.product-container {
  margin: 3rem auto 15rem auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.excel-title {
  font-size: 1.5rem;
  padding: 20px 15px;
  color: white;
  font-weight: bold;
}
.excel-title.encabezado {
  margin-top: 1rem;
  background-color: var(--primary-color);
}
.excel-title.validez {
  margin-bottom: 2rem;
  background-color: var(--azteca-naranja);
}
.total-products {
  font-size: 0.95rem;
  text-transform: uppercase;
  font-weight: bold;
  color: #666;
}
.sort-products {
  margin: 1rem 0;
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}
.sort-products div label {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
  text-transform: uppercase;
  font-weight: bold;
  color: var(--primary-color);
}
button {
  margin: 1rem 0 2rem 0;
  display: inline-block;
  padding: 5px 20px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: #333;
  background-color: #f5f5f5;
  border: 2px solid #333;
  border-radius: 5px;
  cursor: pointer;
  transition: color 0.3s ease, background-color 0.3s ease, transform 0.3s ease;
}

button:hover {
  color: #ffffff;
  background-color: #333;
  transform: translateY(-2px);
}

button.disabled {
  background-color: #b0bec5;
  color: #9e9e9e;
  cursor: not-allowed;
  box-shadow: none;
}
</style>
