<template>
  <NavBar
    :items="navBarItemsWithAuth"
    :config="{ bgNav: 'white', fgLink: '#2c3e50' }"
  ></NavBar>

  <div class="container" style="margin-top: 8rem">
    <TitleView
      title="Listado ofertas activas"
      subtitle="Gestionar las ofertas activas, descargar imagen, eliminar"
    ></TitleView>
  </div>

  <FullScreenImage
    v-show="toggleFullImg"
    :rutaimagen="rutaImgActual"
    :altimagen="altActualImg"
    @cerrarImagen="toggleFullImg = !toggleFullImg"
  ></FullScreenImage>

  <div class="container offact-length">
    Número de ofertas activas al día de hoy:
    <span> {{ ofertasItems.length }}</span>
  </div>

  <div class="container">
    <TableComponent :headers="encabezadosTabla" :items="ofertasItems">
      <template #imagenslot="{ item }">
        <a
          v-if="item.offact_ruta_imagen"
          href="#"
          @click.prevent="
            mostrarImagen(item.offact_ruta_imagen, item.pof_descripcion, $event)
          "
          >Ver imagen</a
        >
        <p v-else class="m-0 col-noimg">Sin imagen</p>
      </template>
      <template #accionesslot="{ item }">
        <button
          type="button"
          class="btn btn-sm btn-outline-danger"
          title="Eliminar producto"
          @click.prevent="
            eliminarOferta(item.offact_ID as string, item.pof_descripcion)
          "
        >
          <i class="fa-solid fa-trash"></i>
        </button>
      </template>
    </TableComponent>
  </div>
</template>

<script lang="ts" setup>
import NavBar from "@/components/NavBar.vue";
import FullScreenImage from "@/components/FullScreenImage.vue";
import TitleView from "@/components/TitleView.vue";
import TableComponent from "@/components/TableComponent.vue";
import {
  BASE_API_URL,
  navBarItemsWithAuth,
} from "@/constants/generalConstants";
import { OfferDBInterface } from "@/interfaces/ApiResponseInterfaces";
import { IHeader } from "@/interfaces/ComponentInterfaces";
import { newApiRequest } from "@/services/apiRequest";
import { mostrarAlerta, mostrarToast } from "@/services/sweetAlertService";
import { Ref, ref, onBeforeMount, computed } from "vue";
import { DateTime } from "luxon";
import { obtenerRutaImagen } from "@/utils/fileutilities";

const ofertasItems: Ref<OfferDBInterface[]> = ref([]);
const toggleFullImg = ref(false);
const rutaImgActual: Ref<string> = ref("");
const altActualImg: Ref<string> = ref("");

const encabezadosTabla: IHeader[] = [
  {
    key: "offact_productoID",
    label: "Código",
    sorteable: true,
  },
  {
    key: "pof_descripcion",
    label: "Descripción",
    sorteable: true,
  },
  {
    key: "offact_precio",
    label: "Precio",
    sorteable: true,
  },
  {
    key: "offact_cantidad",
    label: "Cantidad",
    sorteable: true,
  },
  {
    key: "offact_categoria",
    label: "Categoría",
    sorteable: true,
  },
  {
    key: "validez",
    label: "Validez",
  },
  {
    key: "imagenslot",
    label: "Imagen",
    slot: true,
  },
  {
    key: "accionesslot",
    label: "Acciones",
    slot: true,
  },
];

const mostrarImagen = (ruta: string, desc: string, e: Event) => {
  rutaImgActual.value = obtenerRutaImagen(ruta);
  altActualImg.value = desc + "-imagen";
  toggleFullImg.value = true;
};

const eliminarOferta = async (codigo: string, nombre: string) => {
  const respuesta = await mostrarAlerta({
    title: "Eliminar Producto",
    text: `¿Estás segur@ que quieres eliminar la oferta de ${nombre}?`,
    showCancelButton: true,
    icon: "error",
    iconHtml: '<i class="fa-solid fa-trash-can"></i>',
  });

  try {
    if (respuesta.isConfirmed) {
      const productoEliminado = await newApiRequest("/ofertas", {
        method: "DELETE",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify({ codigo }),
      });

      fetchOfertas();
      mostrarToast({
        title: "Accion realizada",
        text: "Producto eliminado",
        icon: "info",
      });
    }
    return;
  } catch (error) {
    mostrarAlerta({ title: "Error", text: error as string, icon: "error" });
  }
};

const fetchOfertas = async () => {
  try {
    const { ofertas } = await newApiRequest("/ofertas");

    const ofertasMapeadas = (ofertas as Array<OfferDBInterface>).map((item) => {
      const fechaInicial = DateTime.fromISO(item.offact_inicio as string)
        .toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)
        .toUpperCase();
      const fechaFinal = DateTime.fromISO(item.offact_final as string)
        .toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)
        .toUpperCase();
      return {
        ...item,
        validez: `${fechaInicial} al ${fechaFinal}`,
      };
    });

    ofertasItems.value = ofertasMapeadas;
  } catch (error) {
    mostrarAlerta({ title: "Error", text: error as string, icon: "error" });
  }
};

onBeforeMount(() => {
  fetchOfertas();
});
</script>

<style scoped>
.filtro-container {
  margin: 3rem auto 1rem auto;
  display: flex;
  justify-content: center;
  gap: 10px;
}
.fila-producto {
  padding: 1.3rem 0;
}
.offact-length {
  margin-top: 4rem;
  margin-bottom: 0.5rem;
  text-align: start;
  color: #aaa;
}

.col-noimg {
  color: #ccc;
}
.table-responsive {
  margin: 0 auto 15rem auto;
}
.form-control,
.form-select {
  width: min(300px, 90%);
}
</style>
