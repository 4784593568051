<template>
  <h3>Imagenes para fondo de ofertas</h3>
  <div class="img-container">
    <template v-for="(image, index) in itemImages" :key="image.imgbg_ID">
      <div class="plantilla-cont">
        <span>Plantilla {{ index + 1 }}</span>
        <img
          class="img-bg"
          :src="obtenerRutaImagen(image.imgbg_ruta)"
          :alt="`imagenoferta${image.imgbg_ID}`"
          @click="handleImageClick"
        />
      </div>
    </template>
  </div>
  <div v-show="showFull" class="full-preview" @click="closeImageFull">
    <span class="material-symbols-outlined close-fs"> cancel </span>
    <img :src="actualImg" alt="imagenfullscreen" />
  </div>
</template>

<script lang="ts" setup>
import { ImageBg } from "@/interfaces/GeneralInterfaces";
import { Ref, defineProps, ref } from "vue";
import { obtenerRutaImagen } from "@/utils/fileutilities";

const actualImg: Ref<string> = ref("");
const showFull: Ref<boolean> = ref(false);

const props = defineProps<{ itemImages: ImageBg[] }>();

const handleImageClick = (e: Event) => {
  showFull.value = true;
  const image = e.target as HTMLImageElement;
  const rutaImagenSeleccionada = image.src;
  actualImg.value = rutaImagenSeleccionada;
};

const closeImageFull = () => (showFull.value = !showFull.value);
</script>

<style scoped>
h3 {
  color: #aaa;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 1.3rem;
}
.img-container {
  position: sticky;
  top: 80px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  gap: 15px;
  justify-content: center;
  background-color: white;
  overflow: auto;
}
.img-bg {
  cursor: pointer;
  width: 100%;
}
.full-preview {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 550;
  background-color: #333;
}
.full-preview img {
  object-fit: contain;
  height: 100%;
  width: 100%;
}
.close-fs {
  position: absolute;
  top: 25px;
  right: 25px;
  padding: 5px;
  border-radius: 50%;
  background-color: white;
  transition: 0.3s all ease;
  cursor: pointer;
  user-select: none;
}
.close-fs:hover {
  color: red;
}
.plantilla-cont {
  width: 150px;
  min-width: 145px;
  display: flex;
  flex-direction: column;
}
</style>
