<template>
  <div class="off-container" :style="{ '--color-fondo': bgColor }">
    <h3>
      <i :class="`fa-solid me-3 ${faiconname}`"></i>
      {{ categoriaOfertas.toUpperCase() }}
      <i class="ms-3 fa-solid fa-caret-down"></i>
    </h3>
    <div class="img-container">
      <img
        v-for="of in ofertas"
        :key="of.offact_ID"
        :src="obtenerRutaImagen(of.offact_ruta_imagen as string)"
        :alt="of.offact_categoria"
        @click="emitImagenCompleta(of)"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { BASE_API_URL } from "@/constants/generalConstants";
import { OfferDBInterface } from "@/interfaces/ApiResponseInterfaces";
import { ListaOfertasInterface } from "@/interfaces/ComponentInterfaces";
import { obtenerRutaImagen } from "@/utils/fileutilities";
const props = defineProps<ListaOfertasInterface>();

const emit = defineEmits<{
  (e: "imagenCompleta", value: OfferDBInterface): void;
}>();

const emitImagenCompleta = (ofertaClickeada: OfferDBInterface) => {
  emit("imagenCompleta", ofertaClickeada);
};
</script>

<style scoped>
.img-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
}
img {
  cursor: pointer;
  display: block;
  width: min(100%, 250px);
  transition: 0.3s all ease;
}
img:hover {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
}
h3 {
  width: fit-content;
  margin: 5rem auto 5rem 0;
  padding: 15px 25px;
  font-size: 1.25rem;
  font-weight: 700;
  color: #fff;
  background-color: var(--color-fondo);
  background-image: url("https://www.transparenttextures.com/patterns/cartographer.png");
  text-transform: uppercase;
  border-radius: 8px;
}
</style>
