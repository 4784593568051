import { UsuarioInterface } from "@/interfaces/ApiResponseInterfaces";
import { newApiRequest } from "@/services/apiRequest";
import { mostrarToast } from "@/services/sweetAlertService";
import { defineStore } from "pinia";
import { Ref, ref, computed } from "vue";
import { useRouter } from "vue-router";

export const useAuthStore = defineStore("auth", () => {
  const token: Ref<string | null> = ref(null);
  const error = ref(null);
  const user: Ref<UsuarioInterface | null> = ref(null);

  const existeToken = localStorage.getItem("token-landing");
  const existeUsuario = localStorage.getItem("user-landing");

  const router = useRouter();

  if (existeToken) {
    token.value = existeToken;
  }
  if (existeUsuario) {
    user.value = JSON.parse(existeUsuario);
  }

  const login = async (username: string, password: string) => {
    try {
      const sesion: { token: any; user: UsuarioInterface } =
        await newApiRequest("/login", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ username, password }),
        });

      user.value = sesion.user;
      token.value = sesion.token;

      localStorage.setItem("token-landing", sesion.token);
      localStorage.setItem("user-landing", JSON.stringify(sesion.user));

      router.replace({ name: "laofertasactivas" });
      mostrarToast({ text: "Sesión iniciada correctamente", icon: "info" });
    } catch (error) {
      console.error(error);
      mostrarToast({
        icon: "error",
        title: "Error",
        text: error as string,
        timer: 4500,
        showCloseButton: true,
      });
    }
  };

  const logout = () => {
    mostrarToast({ text: "Sesión cerrada correctamente", icon: "info" });
    // user.value = null;
    token.value = null;

    localStorage.removeItem("token-landing");
    localStorage.removeItem("user-landing");

    router.replace({ name: "lalogin" });
  };

  const roleUsuario = computed(() => user.value?.user_role);

  return { token, error, login, logout, roleUsuario };
});
