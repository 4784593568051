<template>
  <section class="cards-container">
    <div
      v-for="card in cards"
      :key="card.description"
      class="_card"
      :style="'--clr: ' + card.color"
    >
      <div>
        <i
          :class="card.icon"
          class="card-icon"
          style="--fa-animation-duration: 3s"
        ></i>
      </div>
      <span class="card-title">{{ card.title }}</span>
      <span class="card-description">{{ card.description }}</span>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { CardInterface } from "@/interfaces/GeneralInterfaces";

const cards: Array<CardInterface> = [
  {
    icon: "fa-solid fa-ranking-star fa-shake",
    title: "Calidad",
    description:
      "En nuestras tiendas solo encontrarás productos de la más alta calidad",
    color: "#FFBE0B",
  },
  {
    icon: "fa-regular fa-handshake fa-shake",
    title: "Servicio al cliente",
    description:
      "Acercate a cualquiera de nuestros empleados con gusto te ayudarán",
    color: "#777da7",
  },
  {
    icon: "fa-sharp fa-solid fa-piggy-bank fa-shake",
    title: "Precios Bajos",
    description: "Ahorra en tus compras con nuestros precios inigualables",
    color: "#249eff",
  },
  {
    icon: "fa-solid fa-apple-whole fa-shake",
    title: "La Mayor Frescura",
    description:
      "Encuentra productos frescos en cualquiera de nuestras sucursales",
    color: "green",
  },
  {
    icon: "fa-solid fa-tag fa-shake",
    title: "Constantes Ofertas",
    description:
      "Nos mantenemos aplicando constantes ofertas para que siempre obtengas los mejores precios",
    color: "#C33149",
  },
  {
    icon: "fa-solid fa-box-open fa-shake",
    title: "Mayoreo y menudeo",
    description: "Nos adaptamos a cualquier tipo de cliente y sus necesidades ofreciendo distintos precios en función de la compra",
    color: "#074F57",
  },
];
</script>

<style scoped>
.cards-container {
  margin: 0 auto;
  width: min(95%, 1180px);
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 2rem 1rem;
}
._card {
  display: flex;
  flex-direction: column;
  padding: 1.5rem 1.3rem;
  width: 250px;
  height: 280px;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.6);
}
.card-icon {
  font-size: 2.5rem;
  justify-self: center;
  color: var(--clr);
}
.card-title {
  font-size: 1.15rem;
  color: var(--clr);
  font-weight: bold;
  font-family: "Poppins", sans-serif;
}
.card-description {
  text-align: center;
  font-family: "Poppins", sans-serif;
  opacity: 0.9;
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 1, "wght" 200, "GRAD" 0, "opsz" 24;
}
</style>
