import { linkNavegacion } from "@/interfaces/ComponentInterfaces";

export const BASE_API_URL = "/landing";
// http://localhost:3002/landing BASE URL DEV
// /landing BASE URL PROD
export const ENVIROMENT: "PROD" | "DEV" = "PROD";

export const navBarItems: linkNavegacion[] = [
  {
    ruta: "/azteca/inicio",
    texto: "Inicio",
    icono: "home",
  },
  {
    ruta: "/azteca/ofertas/industrial",
    texto: "Ofertas Industrial",
    icono: "sell",
  },
  {
    ruta: "/azteca/sucursales",
    texto: "Localiza nuestras sucursales",
    icono: "location_on",
  },
];

export const navBarItemsWithAuth: linkNavegacion[] = [
  {
    ruta: "/azteca/inicio",
    texto: "Ir a landing",
    icono: "arrow_circle_left",
  },
  {
    ruta: "/azteca/ofertas/excel",
    texto: "Subir Excel",
    icono: "upload_file",
  },
  {
    ruta: "/azteca/ofertas/productos",
    texto: "Todos Productos",
    icono: "sell",
  },
  {
    ruta: "/azteca/ofertas/activas",
    texto: "Ofertas Activas",
    icono: "sell",
  },
  {
    ruta: "/azteca/usuarios",
    texto: "Usuarios",
    icono: "person",
  },
  {
    ruta: "/azteca/fondos",
    texto: "Plantillas",
    icono: "image",
  },
];
