<template>
  <div>
    <h1>404 - Página no encontrada</h1>
    <p>La página que estás buscando no existe.</p>
    <router-link to="/azteca/inicio">Volver al inicio</router-link>
  </div>
</template>

<script lang="ts" setup></script>
<style scoped>
div {
  display: flex;
  flex-direction: column;
  gap: 25px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: #f7971e; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #ffd200,
    #f7971e
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #ffd200,
    #f7971e
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
a{
    color: white;
}
</style>
