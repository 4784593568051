<template>
  <form @submit.prevent="handleSubmit" class="d-flex flex-column">
    <div>
      <label class="form-label d-block text-start" for="username"
        >Usuario</label
      >
      <input
        class="form-control"
        type="text"
        id="username"
        name="username"
        required
      />
    </div>

    <div>
      <label class="form-label d-block text-start" for="username"
        >Nombre propietario</label
      >
      <input
        class="form-control"
        type="text"
        id="name"
        name="name"
        maxlength="80"
        required
      />
    </div>

    <div>
      <label for="role" class="form-label d-block text-start">Nivel</label>
      <select name="role" id="role" class="form-select" required>
        <option value="empleado">Empleado</option>
        <option value="admin">Administrador</option>
      </select>
    </div>

    <div>
      <label class="form-label d-block text-start" for="username"
        >Contraseña</label
      >
      <div class="form-pass">
        <input
          class="form-control"
          :type="passType"
          id="password"
          name="password"
          minlength="6"
          required
        />
        <i
          :class="`fa-regular ${passIcon} form-reveal-pass`"
          @click="togglePassView"
        ></i>
      </div>
    </div>
    <input
      class="btn btn-outline-secondary"
      type="submit"
      value="Crear usuario"
    />
  </form>
</template>

<script lang="ts" setup>
import { newApiRequest } from "@/services/apiRequest";
import { mostrarAlerta, mostrarToast } from "@/services/sweetAlertService";
import { Ref, ref } from "vue";

const emit = defineEmits<{ (e: "usuarioCreado"): void }>();
const passIcon: Ref<string> = ref("fa-eye");
const passType: Ref<string> = ref("password");

const togglePassView = (): void => {
  passIcon.value == "fa-eye"
    ? (passIcon.value = "fa-eye-slash")
    : (passIcon.value = "fa-eye");

  passType.value == "password"
    ? (passType.value = "text")
    : (passType.value = "password");
};

const handleSubmit = async (e: Event) => {
  try {
    const form = e.target as HTMLFormElement;
    const formData = new FormData(form);
    const usuario = await newApiRequest("/crear-usuario", {
      method: "POST",
      body: formData,
    });

   form.reset();     
    emit("usuarioCreado");
  } catch (error) {
    mostrarAlerta({ title: "Error", text: error as string, icon: "error" });
  }
};
</script>
<style scoped>
form {
  gap: 15px;
}
.form-pass {
  position: relative;
}
.form-reveal-pass {
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
}
.form-reveal-pass:hover {
  color: var(--primary-color);
}
.form-label {
  color: #999;
}
</style>
