<template>
  <NavBar
    :items="navBarItemsWithAuth"
    :config="{ bgNav: 'white', fgLink: '#2c3e50' }"
  ></NavBar>

  <div class="container" style="margin-top: 8rem">
    <TitleView
      title="Listado de productos"
      subtitle="Consultar/Editar imagenes, eliminar productos"
    ></TitleView>
  </div>

  <FullScreenImage
    v-show="toggleFullImg"
    :rutaimagen="rutaImgActual"
    :altimagen="altActualImg"
    @cerrarImagen="toggleFullImg = !toggleFullImg"
  ></FullScreenImage>

  <div class="container" style="margin-bottom: 15rem; margin-top: 3rem">
    <TableComponent :headers="encabezadosProductos" :items="productosItems">
      <template #imagenslot="{ item }">
        <div v-if="item.pof_imagen" class="btn-group" role="group">
          <button
            type="button"
            class="btn btn btn-outline-primary"
            @click.prevent="
              mostrarImagen(item.pof_imagen, item.pof_descripcion, $event)
            "
            title="Ver imagen"
          >
            <i class="fa-regular fa-image"></i>
          </button>
          <button
            type="button"
            class="btn btn btn-outline-primary"
            title="Descargar Imagen"
            @click.prevent="
              descargarImagen(item.pof_imagen, item.pof_descripcion)
            "
          >
            <i class="fa-solid fa-download"></i>
          </button>
        </div>
        <p v-else class="m-0 col-noimg">Sin imagen</p>
      </template>

      <template v-if="authStore.roleUsuario == 'admin'" #actionslot="{ item }">
        <button
          type="button"
          class="btn btn-sm btn-outline-danger"
          title="Eliminar producto"
          @click.prevent="eliminarProducto(item.pof_ID as string)"
        >
          <i class="fa-solid fa-trash"></i>
        </button>
      </template>
    </TableComponent>
  </div>
</template>

<script lang="ts" setup>
import NavBar from "@/components/NavBar.vue";
import TitleView from "@/components/TitleView.vue";
import FullScreenImage from "@/components/FullScreenImage.vue";
import TableComponent from "@/components/TableComponent.vue";
import {
  BASE_API_URL,
  navBarItemsWithAuth,
} from "@/constants/generalConstants";
import { ProductoInterface } from "@/interfaces/ApiResponseInterfaces";
import { newApiRequest } from "@/services/apiRequest";
import { mostrarAlerta, mostrarToast } from "@/services/sweetAlertService";
import { Ref, ref, onBeforeMount, computed } from "vue";
import { obtenerExtension, obtenerRutaImagen } from "@/utils/fileutilities";
import { IHeader } from "@/interfaces/ComponentInterfaces";
import { useAuthStore } from "@/stores/auth";

const productosItems: Ref<ProductoInterface[]> = ref([]);
const filtrandoPor: Ref<string> = ref("");
const entradaCodigo: Ref<string | null> = ref(null);
const entradaDescripcion: Ref<string | null> = ref(null);
const toggleFullImg = ref(false);
const rutaImgActual: Ref<string> = ref("");
const altActualImg: Ref<string> = ref("");

const authStore = useAuthStore();

const productosFiltrados = computed(() => {
  if (filtrandoPor.value == "codigo" && entradaCodigo.value != "") {
    return productosItems.value.filter((p) =>
      p.pof_ID.toString().includes(entradaCodigo.value as string)
    );
  } else if (
    filtrandoPor.value == "descripcion" &&
    entradaDescripcion.value != ""
  ) {
    return productosItems.value.filter((p) =>
      p.pof_descripcion
        .toLowerCase()
        .includes(entradaDescripcion.value?.toLowerCase() as string)
    );
  } else {
    return productosItems.value || [];
  }
});

const encabezadosProductos: IHeader[] = [
  {
    key: "pof_ID",
    label: "Código",
    sorteable: true,
  },
  {
    key: "pof_descripcion",
    label: "Descripción",
    sorteable: true,
  },
  {
    key: "imagenslot",
    label: "Imagen",
    slot: true,
  },
  {
    key: "actionslot",
    label: "Acciones",
    slot: true,
  },
];

const mostrarImagen = (ruta: string, desc: string, e: Event) => {
  rutaImgActual.value = obtenerRutaImagen(ruta);
  altActualImg.value = desc + "-imagen";
  toggleFullImg.value = true;
};

const descargarImagen = async (
  rutaDescarga: string,
  nombreProducto: string
) => {
  const params = new URLSearchParams();
  const ext = obtenerExtension(rutaDescarga);
  params.set("rutaimagen", rutaDescarga);
  try {
    const descarga = await newApiRequest(
      `/descargar-imagen-producto?${params.toString()}`,
      undefined,
      undefined,
      true
    );
    const urlDescarga = URL.createObjectURL(descarga);
    const enlace: HTMLAnchorElement = document.createElement("a");
    enlace.href = urlDescarga;
    enlace.download = `${nombreProducto.replaceAll(".", "").trim()}.${ext}`;
    enlace.click();
    enlace.remove();
    URL.revokeObjectURL(urlDescarga);
  } catch (error: any) {
    const errmge = String(error).replaceAll("Error:", "");
    mostrarAlerta({ title: "Error", text: errmge, icon: "error" });
  }
};

const eliminarProducto = async (codigo: string) => {
  const respuesta = await mostrarAlerta({
    title: "Eliminar Producto",
    text: "¿Estás segur@ que quieres eliminar el producto?, se borrará su imagen asociada y el registro del mismo",
    showCancelButton: true,
    icon: "error",
    iconHtml: '<i class="fa-solid fa-trash-can"></i>',
  });

  try {
    if (respuesta.isConfirmed) {
      const productoEliminado = await newApiRequest("/productos", {
        method: "DELETE",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify({ codigo }),
      });
      fetchProducts();
      mostrarToast({
        title: "Accion realizada",
        text: "Producto eliminado",
        icon: "info",
      });
    }
    return;
  } catch (error) {
    mostrarAlerta({ title: "Error", text: error as string, icon: "error" });
  }
};

const fetchProducts = async () => {
  try {
    const { productos } = await newApiRequest("/productos");
    productosItems.value = productos as ProductoInterface[];
  } catch (error) {
    mostrarAlerta({ title: "Error", text: error as string, icon: "error" });
  }
};

onBeforeMount(() => {
  fetchProducts();
});
</script>

<style scoped>
.filtro-container {
  margin: 3rem auto 1rem auto;
  display: flex;
  justify-content: center;
  gap: 10px;
}
.fila-producto {
  padding: 1.3rem 0;
}
.col-noimg {
  color: #ccc;
}
.table-responsive {
  margin: 4rem auto 15rem auto;
}
.form-control {
  width: min(300px, 90%);
}
</style>
