<template>
  <section class="video-container">
    <div class="overlay"></div>
    <div class="video-logo">
      <img src="../assets/logo.png" alt="logo-azteca-hero" />
      <span>"Cuidando tu economía con un concepto diferente"</span>
    </div>
    <video autoplay muted loop playsinline class="hero-video">
      <source src="../assets/videoazteca.mp4" type="video/mp4" />
      El navegador no soporta la etiqueta de video.
    </video>
  </section>
</template>

<script lang="ts" setup></script>

<style scoped>
.video-container {
  position: relative;
  width: 100%;
  height: 100vh;
}
.hero-video {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: bottom;
  width: 100%;
  height: 100%;
  z-index: 5;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
}
.video-logo {
  position: absolute;
  width: min(80%, 300px);
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 15;
  opacity: 1;
  filter: brightness(110%);
}
.video-logo span {
  font-size: 2rem;
  font-family: "Ubuntu", sans-serif;
  color: white;
  font-style: italic;
}
img {
  width: 100%;
}
</style>
