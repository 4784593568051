<template>
  <div class="full-img-container">
    <button class="btn btn-sm btn-danger" @click="cerrarImagen">
      <i class="fa-solid fa-xmark"></i>
    </button>
    <img :src="rutaimagen" :alt="altimagen" class="full-img" />
  </div>
</template>

<script lang="ts" setup>
const emit = defineEmits<{ (e: "cerrarImagen"): void }>();

interface FullScreenProps {
  rutaimagen: string;
  altimagen: string;
}

const props = defineProps<FullScreenProps>();

const cerrarImagen = () => {
  emit("cerrarImagen");
};
</script>

<style scoped>
.full-img-container {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #333;
  z-index: 550;
}
.full-img {
  width: min(800px, 100%);
}
button {
  position: absolute;
  right: 35px;
  top: 35px;
}
</style>
