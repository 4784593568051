<template>
  <div v-if="visibility" class="modal-overlay">
    <div class="modal-content" @click.stop>
      <span class="material-symbols-outlined modal-close" @click="toggleModal">
        close
      </span>
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, defineEmits } from "vue";

const emit = defineEmits<{
  (event: "toggleModal", value: boolean): void;
}>();

const toggleModal = () => {
  emit("toggleModal", false);
};

interface ModalProps {
  title: string;
  visibility: boolean;
}

const props = defineProps<ModalProps>();
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1010;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  width: fit-content;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #333;
  border: none;
  cursor: pointer;
}
.modal-close:hover{
  color: crimson;
}
</style>
