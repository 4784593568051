<template>
  <div class="product-item">
    <div class="info">
      <h3 class="mb-4">{{ name }}</h3>
      <div class="response-mge mb-3" v-if="responseMge">
        {{ responseMge }}
      </div>
      <Button
        v-if="showBtnAddImage && !imagenGuardada"
        @click="toggleModal"
        buttonText="Agregar Imagen"
        buttonType="button"
        icon="add_a_photo"
        textColor="ghostwhite"
        buttonColor="darkslateblue"
      ></Button>
      <p class="product-label">
        Código: <span>{{ id }}</span>
      </p>
      <p class="product-label">
        Precio: $<span>{{ price }}</span>
      </p>
      <p class="product-label">
        Cantidad:<span>{{ quantity }}</span>
      </p>
    </div>
    <form
      v-if="!existsOffer && availableImages.length"
      class="options"
      @submit.prevent="handleProductOffer"
    >
      <fieldset>
        <legend class="form-title">Elige la plantilla</legend>
        <div class="d-flex gap-2 justify-content-center flex-wrap">
          <label v-for="(imagen, index) in availableImages"
            ><input
              type="radio"
              name="plantilla"
              :value="imagen.imgbg_ruta"
              required
            />Plantilla {{ index + 1 }}</label
          >
        </div>
      </fieldset>

      <fieldset class="mt-3">
        <legend class="form-title">Elige categoría</legend>
        <div class="d-flex gap-2 justify-content-center flex-wrap">
          <label v-for="catg in categoriasOfertas"
            ><input
              type="radio"
              name="categoria"
              :value="catg.value"
              required
            />{{ catg.texto }}</label
          >
        </div>
      </fieldset>

      <Button
        v-if="!existsOffer"
        buttonText="Publicar Oferta"
        buttonType="submit"
        icon="public"
        textColor="ghostwhite"
        buttonColor="darkslateblue"
      ></Button>

      <div v-if="loading">Cargando...</div>
    </form>
    <p v-else-if="!availableImages.length" class="no-bg-img">
      No se carga el formulario porque no existen imagenes de fondo para las
      ofertas
    </p>
  </div>
</template>

<script setup lang="ts">
import { newApiRequest } from "@/services/apiRequest";
import {
  defineProps,
  onBeforeMount,
  defineEmits,
  ref,
  Ref,
  computed,
  ComputedRef,
} from "vue";
import Button from "@/components/Button.vue";
import { ImageBg } from "@/interfaces/GeneralInterfaces";

const categoriasOfertas = [
  { value: "verdura", texto: "Verdura" },
  { value: "fruta", texto: "Fruta" },
  { value: "abarrote", texto: "Abarrotes" },
];

const emit = defineEmits<{
  (
    event: "toggleModal",
    idSelected: string | number,
    descSelected: string
  ): void;
}>();

const toggleModal = () => {
  emit("toggleModal", props.id, props.name as string);
};

interface Props {
  id: number | string;
  name: string | number;
  price: number | string;
  quantity: string | number;
  startDate: string;
  endDate: string;
  availableImages: ImageBg[];
  imagenGuardadaCorrectamente: string | number | null;
}

const imagenGuardada: ComputedRef<boolean> = computed(() => {
  if (props.imagenGuardadaCorrectamente == props.id) {
    responseMge.value = "";
    return true;
  }
  return false;
});

// Define las props que el componente aceptará
const props = defineProps<Props>();
const responseMge: Ref<string> = ref("");
const existsOffer: Ref<boolean> = ref(false);
const showBtnAddImage: Ref<boolean> = ref(false);
const loading: Ref<boolean> = ref(false);

const handleProductOffer = async (e: Event) => {
  loading.value = true;
  const formData = new FormData(e.target as HTMLFormElement);
  formData.append("codigo", props.id as string);
  formData.append("precio", props.price as string);
  formData.append("cantidad", props.quantity as string);
  formData.append("nombre", props.name as string);
  formData.append("fechaInicial", props.startDate);
  formData.append("fechaFinal", props.endDate);
  // for (const [key, value] of formData.entries()) {
  //   console.log(key, value);
  // }

  try {
    const ofertaSubida = await newApiRequest("/guardar-oferta", {
      method: "POST",
      body: formData,
    });

    if (ofertaSubida) {
      comprobeOfferAlreadyExists();
    }
  } catch (error) {
    const errorMge = String(error).replace("Error:", "");
    errorMge.includes("No hay imagen")
      ? (showBtnAddImage.value = true)
      : (showBtnAddImage.value = false);

    responseMge.value = errorMge;
  } finally {
    loading.value = false;
  }
};

const comprobeOfferAlreadyExists = async () => {
  let params = new URLSearchParams();
  params.append("idProducto", props.id as string);
  params.append("fechaInicial", props.startDate);
  params.append("fechaFinal", props.endDate);
  const url = `/comprobar-oferta?${params.toString()}`;
  try {
    const ofertaComprobada = await newApiRequest(url);
    if (ofertaComprobada.existeOferta && ofertaComprobada.existeOferta == 1) {
      existsOffer.value = true;
      showBtnAddImage.value = false;
      responseMge.value = "Ya hay una oferta activa";
    }
  } catch (error) {
    console.error("Error al comprobar si existe oferta para " + props.id);
  }
};

onBeforeMount(() => {
  comprobeOfferAlreadyExists();
});
</script>

<style scoped>
.product-item {
  width: min(300px, 90%);
  border: 1px solid #eee;
  padding: 25px 20px;
  margin: 10px;
  background-color: #fafafa;
  height: fit-content;
  min-height: 330px;
}
.response-mge {
  margin-bottom: 1rem;
  padding: 15px 10px;
  text-transform: uppercase;
  font-size: 0.9rem;
  font-weight: 500;
  background-color: #cde77f;
}

h3,
.form-title {
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 1px;
  color: var(--primary-color);
}

label > input {
  margin-right: 5px;
}
.no-bg-img {
  color: crimson;
  font-size: 0.85rem;
}
</style>
