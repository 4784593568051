<template>
  <section class="__hero">
    <img class="hero-img" src="../assets/apache-removebg.png" alt="" />
    <div class="hero-info mt-3">
      <h1 class="hero-title">Abarrotes <span>Azteca</span></h1>
      <h2 class="hero-subtitle mb-3">
        Comprometidos con las FAMILIAS, apoyando con los
        <span>precios más bajos y la mejor calidad</span> para todos
      </h2>
      <a @click="scrollToComponent" class="hero-btn">Ver ofertas</a>
    </div>
  </section>
</template>

<script lang="ts" setup>
const scrollToComponent = () => {
  const componente2 = document.getElementById("sliderOffers") as HTMLElement;
  if (componente2) {
    componente2.scrollIntoView({ behavior: "smooth", block: "center" });
  }
};
</script>

<style scoped>
.__hero {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  margin: 0 auto;
  height: 100vh;
  width: min(100%, 1200px);
  transition: 2s flex-direction;
}
.hero-info {
  position: relative;
  width: min(85%, 400px);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.hero-title {
  position: relative;
  font-family: "Kanit", sans-serif;
  font-size: 2.5rem;
  text-transform: uppercase;
  color: #7abcc8;
}
.hero-title span {
  font-family: "Mistral", sans-serif;
  font-size: 1.5em;
  text-transform: uppercase;
  color: var(--primary-color);
}
.hero-subtitle {
  font-size: 1.5rem;
  font-family: "Poppins", sans-serif;
}
.hero-subtitle span {
  color: var(--primary-color);
}
.hero-img {
  display: block;
  width: min(60%, 450px);
}
.hero-btn {
  cursor: pointer;
  display: block;
  padding: 0.5rem;
  /* outline: none; */
  border: none;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: bold;
  color: white;
  background-color: #ff5a00;
  transition: 0.6s all ease;
  text-decoration: none;
  text-align: center;
}
.hero-btn:hover {
  letter-spacing: 2px;
  background-color: rgb(241, 85, 0);
}
@media only screen and (max-width: 768px) {
  .__hero {
    flex-direction: column;
  }
}
</style>
