<template>
  <header
    class="nav__container"
    :style="{ '--bg-nav': config.bgNav, '--fg-link': config.fgLink }"
  >
    <router-link to="/" class="nav__logo">
      <img src="../assets/aztecalogo.png" class="nav__img" alt="logo" />
    </router-link>

    <span class="material-symbols-outlined nav__menu" @click="toggleMenu">
      menu
    </span>

    <nav class="nav__list">
      <div v-for="navLink in items" class="nav__link__container">
        <router-link :to="navLink.ruta" class="nav__link">
          <span class="material-symbols-outlined">{{ navLink.icono }} </span>
          {{ navLink.texto }}
        </router-link>
      </div>
      <a
        href="#"
        @click.prevent="authStore.logout()"
        class="nav__link"
        title="Cerrar sesión"
      >
        <span class="material-symbols-outlined" v-if="authStore.token">
          logout
        </span>
      </a>
    </nav>
  </header>
</template>

<script lang="ts" setup>
import { NavBarInterface } from "@/interfaces/ComponentInterfaces";
import { useAuthStore } from "@/stores/auth";

const props = defineProps<NavBarInterface>();
const authStore = useAuthStore();

const toggleMenu = () => {
  const navList = document.querySelector(".nav__list") as HTMLElement;
  navList.classList.toggle("is-active");
};
</script>

<style scoped>
:root {
  --bg-nav: white;
}
.nav__container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 100px;
  background-color: var(--bg-nav);
  z-index: 500;
  /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3); */
}
.nav__logo {
  width: 120px;
}
.nav__img {
  width: 120px;
}
.nav__menu {
  display: none;
  cursor: pointer;
  user-select: none;
}
.nav__menu:hover {
  color: var(--primary-color);
}
.nav__list {
  display: flex;
  gap: 15px;
  align-items: center;
}
.nav__link_container {
  display: flex;
  gap: 3px;
  align-items: center;
}
.nav__link {
  font-weight: bold;
  color: var(--fg-link);
  text-decoration: none;
}
.nav__list .nav__link.router-link-exact-active {
  color: var(--primary-color);
}

.nav__container > .nav__list > div:nth-child(1) > span {
  margin-right: 5px;
}

.material-symbols-outlined {
  vertical-align: text-top;
  font-size: 1.25em;
  color: var(--fg-link);
}

@media screen and (max-width: 992px) {
  .nav__menu {
    display: inline-block;
  }
  .nav__list {
    display: none;
    position: absolute;
    width: 100%;
    top: 80px;
    left: 0px;
    padding: 2.5rem 0;
    gap: 40px;
    flex-direction: column;
    z-index: 1000;
    background-color: var(--bg-nav);
  }
  .nav__list.is-active {
    display: flex;
  }
}

@media screen and (max-width: 768px){
  .nav__container{
    padding: 0 20px;
  }
}
</style>
