<template>
  <NavBar
    :items="navBarItemsWithAuth"
    :config="{ bgNav: 'white', fgLink: '#2c3e50' }"
  ></NavBar>

  <div class="container" style="margin-top: 8rem">
    <TitleView
      title="Gestionar plantillas para ofertas"
      subtitle="Las imagenes para ofertas se generan basadas en una plantilla de 800x800 pixeles, las plantillas subidas deben ser de las medidas mencionadas"
    ></TitleView>

    <button
      class="btn btn-sm btn-outline-primary d-block ms-auto mt-5"
      @click="mostrarModal = !mostrarModal"
    >
      Subir nueva plantilla
    </button>
  </div>

  <div class="container row m-auto mt-2 g-2">
    <div v-for="ele in imagenes" class="col-md-6" style="position: relative">
      <img
        class="img-plantilla"
        :src="obtenerRutaImagen(ele.imgbg_ruta as string)"
        :alt="'plantilla-para-oferta' + ele.imgbg_ID"
      />
      <a href="#" class="btn-delete-plantilla" title="Eliminar plantilla">
        <span
          class="material-symbols-outlined"
          @click.prevent="eliminarPlantilla(ele.imgbg_ID)"
        >
          backspace
        </span>
      </a>
    </div>
  </div>

  <Modal
    :visibility="mostrarModal"
    title="Subir imagen plantilla"
    @toggle-modal="mostrarModal = !mostrarModal"
  >
    <form @submit.prevent="handleSubmitImage">
      <FileInput
        label="Subir nueva plantilla para ofertas"
        short-label="Imagen Plantilla"
        name="imagen-plantilla"
        accept="image/*"
        icon="photo_camera"
        :required="true"
      ></FileInput>
      <button type="submit" class="btn btn-sm btn-primary mt-2">Guardar</button>
    </form>
  </Modal>
</template>

<script lang="ts" setup>
import NavBar from "@/components/NavBar.vue";
import TitleView from "@/components/TitleView.vue";
import FileInput from "@/components/FileInput.vue";
import { navBarItemsWithAuth } from "@/constants/generalConstants";
import { IPlantillaOferta } from "@/interfaces/ApiResponseInterfaces";
import { newApiRequest } from "@/services/apiRequest";
import { mostrarAlerta, mostrarToast } from "@/services/sweetAlertService";
import { Ref, onBeforeMount, ref } from "vue";
import Modal from "@/components/Modal.vue";
import { obtenerRutaImagen } from "@/utils/fileutilities";

const imagenes: Ref<Array<IPlantillaOferta>> = ref([]);

const eliminarPlantilla = async (idImagen: string) => {
  const accion = await mostrarAlerta({
    title: "Eliminar plantilla",
    text: "¿Estás seguro de eliminar esta plantilla irreversiblemente?",
    icon: "question",
    showCancelButton: true,
    confirmButtonText: "Eliminar",
  });

  if (accion.isConfirmed) {
    try {
      const eliminada = await newApiRequest("/imagenes-oferta/fondo", {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ codigo: idImagen }),
      });
      fetchPlantillas();
      mostrarToast({
        text: "Se eliminó correctamente la plantilla",
        icon: "warning",
        timer: 5000,
      });
    } catch (error) {
      console.error(error);
    }
  }
};

const handleSubmitImage = async (e: Event) => {
  const formdata = new FormData(e.target as HTMLFormElement);
  try {
    const imagensubida = await newApiRequest("/imagenes-oferta/fondo", {
      method: "POST",
      body: formdata,
    });
    console.log(imagensubida);
    mostrarToast({
      text: "Se guardó correctamente la nueva plantilla",
      icon: "success",
      timer: 5000,
    });
    mostrarModal.value = !mostrarModal.value;
    fetchPlantillas();
  } catch (error) {
    mostrarAlerta({ title: "Error", text: error as string, icon: "error" });
  }
};

const mostrarModal: Ref<boolean> = ref(false);

const fetchPlantillas = async () => {
  try {
    const plantillas = await newApiRequest("/imagenes-oferta/fondo");
    imagenes.value = plantillas.imagenes as IPlantillaOferta[];
  } catch (error) {
    mostrarAlerta({ title: "Error", text: error as string, icon: "error" });
  }
};

onBeforeMount(() => {
  fetchPlantillas();
});
</script>

<style scoped>
.img-plantilla {
  position: relative;
  width: 100%;
}
.btn-delete-plantilla {
  position: absolute;
  top: 15px;
  right: 15px;
  text-decoration: none;
  color: white;
  z-index: 5;
  transition: 0.3s all ease;
}
.btn-delete-plantilla:hover {
  color: crimson;
}
.material-symbols-outlined {
  font-variation-settings: "FILL" 1, "wght" 600, "GRAD" 0, "opsz" 24;
}
</style>
