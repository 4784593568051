<template>
  <NavBar
    :items="navBarItems"
    :config="{ bgNav: 'white', fgLink: '#2c3e50' }"
  ></NavBar>

  <p v-if="ofertas.length > 0" class="advise-text">
    Las ofertas que se muestran son exclusivas de la sucursal Azteca Abarrotes
    Ciudad Industrial <br />
    Haz click en una imagen para visualizar en pantalla completa
  </p>
  <p v-else class="advise-text">
    Te invitamos a esperar por las proximas mejores ofertas en nuestra sucursal
    de Cd. Industrial...
  </p>
  <div class="container">
    <OfferList
      v-if="ofertasAbarrote.length"
      :ofertas="ofertasAbarrote"
      categoria-ofertas="ofertas en abarrotes"
      bgColor="cornflowerblue"
      faiconname="fa-basket-shopping"
      @imagen-completa="mostrarImagenCompleta"
    ></OfferList>

    <OfferList
      v-if="ofertasVerdura.length"
      :ofertas="ofertasVerdura"
      categoria-ofertas="Ofertas en Verduras"
      bgColor="cornflowerblue"
      faiconname="fa-carrot"
      @imagen-completa="mostrarImagenCompleta"
    ></OfferList>

    <OfferList
      v-if="ofertasFruta.length"
      :ofertas="ofertasFruta"
      categoria-ofertas="Ofertas en Frutas"
      bgColor="cornflowerblue"
      faiconname="fa-apple-whole"
      @imagen-completa="mostrarImagenCompleta"
    ></OfferList>
  </div>

  <FullScreenImage
    v-show="fullImg"
    @cerrar-imagen="toggleFullImg"
    :rutaimagen="rutaImagenFull"
    :altimagen="altImagenFull"
  ></FullScreenImage>

  <WhatsComponent></WhatsComponent>
</template>

<script lang="ts" setup>
import WhatsComponent from "@/components/WhatsComponent.vue";
import { BASE_API_URL, navBarItems } from "@/constants/generalConstants";
import { OfferDBInterface } from "@/interfaces/ApiResponseInterfaces";
import { newApiRequest } from "@/services/apiRequest";
import OfferList from "@/components/OfferList.vue";
import FullScreenImage from "@/components/FullScreenImage.vue";

import NavBar from "@/components/NavBar.vue";

import { Ref, ref, computed, onBeforeMount } from "vue";
import { obtenerRutaImagen } from "@/utils/fileutilities";

const ofertas: Ref<OfferDBInterface[]> = ref([]);
const ofertasFruta = computed(() =>
  ofertas.value.filter((of) => of.offact_categoria == "fruta")
);
const ofertasAbarrote = computed(() =>
  ofertas.value.filter((of) => of.offact_categoria == "abarrote")
);
const ofertasVerdura = computed(() =>
  ofertas.value.filter((of) => of.offact_categoria == "verdura")
);

const fullImg: Ref<boolean> = ref(false);
const rutaImagenFull: Ref<string> = ref("");
const altImagenFull: Ref<string> = ref("");

const toggleFullImg = () => (fullImg.value = !fullImg.value);

const mostrarImagenCompleta = (oferta: OfferDBInterface) => {
  rutaImagenFull.value = obtenerRutaImagen(oferta.offact_ruta_imagen as string);
  altImagenFull.value = `${oferta.offact_categoria}-${oferta.offact_cantidad}-${oferta.offact_productoID}-img`;
  toggleFullImg();
};

const fetchOfertas = async () => {
  try {
    const res = await newApiRequest("/ofertas");
    ofertas.value = res.ofertas;
  } catch (error) {
    alert(String(error));
  }
};

onBeforeMount(() => {
  fetchOfertas();
});
</script>
<style scoped>
.container {
  margin-bottom: 15rem;
}
.advise-text {
  margin-top: 10rem;
  color: #999;
}
</style>
