import { DateTime } from "luxon";

export const extraerFechasDeExcelOfertas = (input: string) => {
  const regex =
    /DIA (\d{2}) DE (\w+) DE (\d{4}) AL (\d{2}) DE (\w+) DE (\d{4})/;
  const match = input.match(regex);

  if (!match) {
    throw new Error("El formato del string no es válido.");
  }

  const dayStart = parseInt(match[1], 10);
  const monthStart = match[2].toLowerCase();
  const yearStart = parseInt(match[3], 10);
  const dayEnd = parseInt(match[4], 10);
  const monthEnd = match[5].toLowerCase();
  const yearEnd = parseInt(match[6], 10);

  // Mapeo de nombres de meses en español a números de meses
  const monthMap: any = {
    enero: 1,
    febrero: 2,
    marzo: 3,
    abril: 4,
    mayo: 5,
    junio: 6,
    julio: 7,
    agosto: 8,
    septiembre: 9,
    octubre: 10,
    noviembre: 11,
    diciembre: 12,
  };

  const monthNumberStart = monthMap[monthStart];
  const monthNumberEnd = monthMap[monthEnd];

  if (!monthNumberStart || !monthNumberEnd) {
    throw new Error("El mes no es válido.");
  }

  // Crear objetos DateTime para las fechas
  const fechaInicial = DateTime.fromObject({
    day: dayStart,
    month: monthNumberStart,
    year: yearStart,
  }).toSQLDate();
  const fechaFinal = DateTime.fromObject({
    day: dayEnd,
    month: monthNumberEnd,
    year: yearEnd,
  }).toSQLDate();

  return { fechaInicial, fechaFinal };
};
