<template>
  <div class="table-container">
    <div class="filter-container">
      <input
        v-model="filterText"
        @input="applyFilter"
        placeholder="Filtrar..."
        class="filter-input"
      />
      <span class="filter-result mt-1">
        Resultados busqueda: {{ sortedAndFilteredItems.length }}
      </span>
    </div>
    <table class="custom-table">
      <thead>
        <tr>
          <th
            v-for="header in headers"
            :key="header.key"
            @click="sort(header.key, header.sorteable)"
            :class="{ 'sortable-header': header.sorteable }"
          >
            {{ header.label }}
            <span v-if="sortKey === header.key" class="sort-indicator">
              {{ sortOrder === "asc" ? "▼" : "▲" }}
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in sortedAndFilteredItems" :key="index">
          <td v-for="header in headers" :key="header.key">
            <slot v-if="header.slot" :name="header.key" :item="item">
              {{ item[header.key] }}
            </slot>
            <template v-else>
              {{ item[header.key] }}
            </template>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup lang="ts">
import { defineProps, ref, computed } from "vue";

import { ITableProps } from "@/interfaces/ComponentInterfaces";

const props = defineProps<ITableProps>();

const filterText = ref("");
const sortKey = ref("");
const sortOrder = ref<"asc" | "desc">("asc");

const applyFilter = () => {
  // La lógica de filtrado se maneja en el computed property
};

const sort = (key: string, sorteable: boolean = false) => {
  if (sorteable) {
    if (sortKey.value === key) {
      sortOrder.value = sortOrder.value === "asc" ? "desc" : "asc";
    } else {
      sortKey.value = key;
      sortOrder.value = "asc";
    }
  }
};

const sortedAndFilteredItems = computed(() => {
  let result = [...props.items];

  // Aplicar filtro
  if (filterText.value) {
    const lowercaseFilter = filterText.value.toLowerCase();
    result = result.filter((item) =>
      Object.values(item).some(
        (value) =>
          value && value.toString().toLowerCase().includes(lowercaseFilter)
      )
    );
  }

  // Aplicar ordenamiento
  if (sortKey.value) {
    result.sort((a, b) => {
      const valueA = a[sortKey.value];
      const valueB = b[sortKey.value];
      if (valueA < valueB) return sortOrder.value === "asc" ? -1 : 1;
      if (valueA > valueB) return sortOrder.value === "asc" ? 1 : -1;
      return 0;
    });
  }

  return result;
});
</script>

<style scoped>
.table-container {
  overflow-x: auto;
}

.filter-container {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.filter-result {
  font-size: 0.9rem;
  color: #bbb;
}

.filter-input {
  width: min(100%, 300px);
  padding: 8px;
  font-size: 14px;
  border: 1px solid #e5e7eb;
  border-radius: 4px;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
}

.custom-table thead {
  background-color: #3f51b5;
}

.custom-table th {
  padding: 12px 16px;
  text-align: center;
  /* font-size: 14px; */
  font-weight: 600;
  color: #e8eaf6;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  border-bottom: 1px solid #e5e7eb;
  user-select: none;
}

.custom-table td {
  padding: 12px 16px;
  /* font-size: 14px; */
  color: #4b5563;
  border-bottom: 1px solid #e5e7eb;
}

.custom-table tbody tr:hover {
  background-color: #e8eaf6;
}

.custom-table tbody tr:last-child td {
  border-bottom: none;
}

.sortable-header {
  cursor: pointer;
}

.sort-indicator {
  margin-left: 4px;
  color: #e8eaf6;
}
</style>
