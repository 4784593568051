<template>
  <div class="grid-gallery">
    <!-- GRID ITEM -->
    <div v-for="item in gridItems" :key="item.alt" class="grid-item">
      <span class="grid-item-desc">{{ item.title }}</span>
      <img :src="item.src" :alt="item.alt" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { GridItemGalleryInterface } from "@/interfaces/GeneralInterfaces";

const gridItems: Array<GridItemGalleryInterface> = [
  {
    title: "Abarrotes",
    src: require("@/assets/abarrotes.jpg"),
    alt: "departamento-abarrotes",
  },
  {
    title: "Carnes Frías",
    src: require("@/assets/carnes.jpg"),
    alt: "departamento-carnes-frias",
  },
  {
    title: "Verduras",
    src: require("@/assets/verdura.jpg"),
    alt: "departamento-verdura",
  },
  {
    title: "Semillas",
    src: require("@/assets/semillas.jpg"),
    alt: "departamento-semillas",
  },
  {
    title: "Frutas",
    src: require("@/assets/frutas.jpg"),
    alt: "departamento-frutas",
  },
  {
    title: "Limpieza",
    src: require("@/assets/limpieza2.avif"),
    alt: "departamento-limpieza",
  },
  {
    title: "Perfumería",
    src: require("@/assets/perfumeria.jpg"),
    alt: "departamento-perfumes",
  },
];
</script>

<style scoped>
h3 {
  text-align: center;
  font-size: 3rem;
  font-family: "Mistral", sans-serif;
}
.grid-gallery {
  margin: 0 auto;
  width: min(100%, 1180px);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-auto-rows: 250px;
  grid-auto-flow: dense;
  grid-gap: 20px;
}
.grid-gallery .grid-item {
  position: relative;
  background-color: #efefef;
  overflow: hidden;
}
.grid-gallery .grid-item .grid-item-desc {
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 10;
  color: whitesmoke;
  background-color: rgba(29, 93, 151, 0.75);
  font-size: 2.5rem;
  padding: 0 2rem;
  text-transform: uppercase;
  font-weight: bold;
}
.grid-gallery .grid-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 1.5s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.grid-gallery .grid-item:hover img {
  transform: scale(1.1);
}
.grid-gallery .grid-item:nth-child(2n - 1) {
  grid-column: span 2;
  grid-row: span 2;
}
.grid-gallery .grid-item:nth-child(2n) {
  grid-column: span 2;
}
.grid-gallery .grid-item:last-child {
  grid-column: span 2;
  grid-row: unset;
}

@media (max-width: 768px) {
  .grid-gallery .grid-item {
    grid-row: unset;
    grid-column: unset;
  }
}
</style>
