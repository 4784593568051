<template>
  <footer class="__footer">
    &copy; Abarrotes Azteca. Todos los derechos reservados.
  </footer>
</template>

<script setup></script>

<style scoped>
.__footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10rem;
  background-color: var(--primary-color);
  width: 100%;
  height: 80px;
  --mask: radial-gradient(19.72px at 50% 27px, #000 99%, #0000 101%)
      calc(50% - 20px) 0/40px 100%,
    radial-gradient(19.72px at 50% -17px, #0000 99%, #000 101%) 50% 10px/40px
      100% repeat-x;
  -webkit-mask: var(--mask);
  mask: var(--mask);
  color: white;
  font-family: "MISTRAL", sans-serif;
  text-transform: uppercase;
}
</style>
