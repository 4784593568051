import Swal, { SweetAlertOptions } from "sweetalert2";
import "@sweetalert2/themes/bootstrap-4";

export const mostrarAlerta = async (options?: SweetAlertOptions) => {
  const result = await Swal.fire({
    title: options?.title || "Sin titulo",
    text: options?.text || "Sin texto",
    allowOutsideClick: false,
    showCloseButton: true,
    customClass: {
      icon: "custom-fa-icon",
    },
    ...options,
  });

  return result;
};

export const mostrarToast = async (options?: SweetAlertOptions) => {
  const result = await Swal.fire({
    toast: true,
    position: "top-end",
    timerProgressBar: true,
    showConfirmButton: false,
    timer: 2500,
    ...options,
  });

  return result;
};
